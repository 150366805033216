import React from 'react';
import { UseFilters } from '../useFilters';
import { SuspicionItemStatus } from '_enums';
import { CrimeLlmSuspicion, SuspicionArticle } from '_types';
import { OverlayWithSpinner } from '_atoms';
import { ManageSuspicions } from './ManageSuspicions';
import { ArticlePreview } from './ArticlePreview/ArticlePreview';
import { ArticleContent } from './ArticleContent';
import classNames from 'classnames';
import {
    HighlightChunks,
    IndicatorsWithTooltip,
} from '../../../../components/_atoms/Highlight/types';
import { InfiniteScrollV2 } from '_molecules';
import { Paging } from '../../../../services/dataService';
import { SuspicionItemInvolvement } from '../../../../types/SuspicionItem';

interface SuspicionDetailsProps {
    suspicionId?: string;
    setFilters: UseFilters['setFilters'];
    articles: SuspicionArticle[];
    selectedArticle?: SuspicionArticle;
    selectArticle: (id: string) => void;
    isLoading?: boolean;
    highlightChunks: HighlightChunks;
    tooltipsForIndicators?: IndicatorsWithTooltip[];
    pagination?: Paging & {
        handlePageChange: (nextPage: number) => void;
    };
    isNextPageLoading: boolean;
    updateSuspicionStatuses: (
        ids: string[],
        status: SuspicionItemStatus,
    ) => Promise<void>;
    isUpdatingSuspicionStatuses: boolean;
    crimeLlmSuspicions: CrimeLlmSuspicion[];
    linkSuspicionItem: (
        suspicionId: string,
        involvement: SuspicionItemInvolvement,
    ) => Promise<void>;
}

export const SuspicionDetails = (
    props: SuspicionDetailsProps,
): React.ReactElement => {
    const {
        articles,
        isLoading,
        selectedArticle,
        selectArticle,
        highlightChunks,
        pagination,
        isNextPageLoading,
        updateSuspicionStatuses,
        isUpdatingSuspicionStatuses,
        suspicionId,
        tooltipsForIndicators,
        crimeLlmSuspicions,
        linkSuspicionItem,
    } = props;

    const parentScrollClass =
        'max-h-inside-content-height h-inside-content-height';

    const childScrollClass = `overflow-y-auto ${parentScrollClass}`;

    return (
        <OverlayWithSpinner
            showOverlay={!!isLoading}
            hideLoader={true}
            className={classNames(parentScrollClass, 'pt-3')}
        >
            <div className="grid grid-cols-12">
                <div
                    className={classNames(
                        'col-span-4 flex flex-col gap-4 pr-4',
                        childScrollClass,
                    )}
                >
                    {articles.map((article) => (
                        <ArticlePreview
                            key={article.id}
                            article={article}
                            onClick={() => {
                                selectArticle(article.entity.id);
                            }}
                            isActive={
                                selectedArticle?.entity.id === article.entity.id
                            }
                        />
                    ))}
                    {pagination && (
                        <InfiniteScrollV2
                            paging={pagination}
                            isLoading={isNextPageLoading}
                            setPageNumber={pagination.handlePageChange}
                        />
                    )}
                </div>
                <div
                    className={classNames(
                        'col-span-5 pl-2 pr-4',
                        childScrollClass,
                    )}
                >
                    {selectedArticle && (
                        <ArticleContent
                            article={selectedArticle}
                            highlightChunks={highlightChunks}
                            indicators={tooltipsForIndicators}
                        />
                    )}
                </div>
                <div
                    className={classNames('col-span-3 pl-2', childScrollClass)}
                >
                    <ManageSuspicions
                        suspicions={
                            selectedArticle?.entity.suspicionItems ?? []
                        }
                        updateSuspicionStatuses={updateSuspicionStatuses}
                        isUpdatingSuspicionStatuses={
                            isUpdatingSuspicionStatuses
                        }
                        activeSuspicionId={suspicionId}
                        crimeLlmSuspicions={crimeLlmSuspicions}
                        linkSuspicionItem={linkSuspicionItem}
                    />
                </div>
            </div>
        </OverlayWithSpinner>
    );
};
