import React, { forwardRef } from 'react';
import { DynamicFormFieldProps } from './form.interface';
import {
    FieldErrors,
    RegisterOptions,
    useController,
    ValidationRule,
} from 'react-hook-form';
import { TFunction, useTranslation } from 'react-i18next';
import { WizardInput } from '../../../../components/_atoms/Input/WizardInput';
import { WizardSelect } from '../../../../components/_atoms/Select/WizardSelect';
import { WizardFormCheckbox } from '../../../../components/_atoms/Checkbox/WizardCheckbox';

export const getNestedErrorMessage = (
    errors: FieldErrors,
    key: string,
): string | undefined => {
    const parts = key.split('.');
    let current: any = errors;

    for (const part of parts) {
        if (part.includes('[') && part.includes(']')) {
            const [arrayName, indexStr] = part.split('[');
            const index = parseInt(indexStr.replace(']', ''), 10);
            current = current?.[arrayName]?.[index];
        } else {
            current = current?.[part];
        }

        if (!current) {
            return undefined;
        }
    }

    return current.message;
};

type ValidatorKey = keyof RegisterOptions;

export const translateValidationMessages = (
    validators: RegisterOptions,
    t: TFunction,
): RegisterOptions => {
    const translatedValidators: RegisterOptions = {};

    (Object.keys(validators) as ValidatorKey[]).forEach((key) => {
        const value = validators[key];

        if (
            typeof value === 'object' &&
            value !== null &&
            'value' in value &&
            'message' in value
        ) {
            translatedValidators[key] = {
                value: value.value,
                message: t(value.message as string),
            } as ValidationRule<boolean>;
        } else if (typeof value === 'string') {
            translatedValidators[key] = t(value) as ValidationRule<boolean>;
        } else {
            translatedValidators[key] = value;
        }
    });

    return translatedValidators;
};

export const DynamicFormField = forwardRef<
    HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement,
    DynamicFormFieldProps
>(({ field: fieldProps, control, arrayPath, arrayIndex }, ref) => {
    const { t } = useTranslation();
    const {
        key,
        labelTranslationKey,
        type,
        placeholder,
        validators,
        className = 'w-full',
        defaultValue = '',
        disabled,
    } = fieldProps;

    const {
        field,
        formState: { errors },
    } = useController({
        name:
            arrayPath && arrayIndex !== undefined
                ? `${arrayPath}[${arrayIndex}].${key}`
                : key,
        control,
        defaultValue,
        disabled,
        ...(validators
            ? { rules: translateValidationMessages(validators, t) }
            : {}),
    });

    const errorMessage = getNestedErrorMessage(
        errors,
        arrayPath && arrayIndex !== undefined
            ? `${arrayPath}[${arrayIndex}].${key}`
            : key,
    );

    const renderField = () => {
        switch (fieldProps.type) {
            case 'text':
            case 'email':
            case 'date':
            case 'password':
                return (
                    <WizardInput
                        type={type}
                        label={t(labelTranslationKey)}
                        errorMsg={errorMessage}
                        required={!!validators?.required}
                        {...field}
                    />
                );
            case 'dateRange':
                return 'start' in fieldProps && 'end' in fieldProps ? (
                    <div
                        id={`${fieldProps.key}`}
                        className="w-full max-w-flow-text-base mx-auto relative border rounded p-2"
                    >
                        <label
                            className="w-full block text-sm font-bold font-jost text-neutral-500 mb-2"
                            htmlFor={`${fieldProps.key}`}
                        >
                            {t(labelTranslationKey)}
                        </label>
                        <div className="flex space-x-4">
                            <span className="w-1/2">
                                <DynamicFormField
                                    field={{
                                        ...fieldProps.start,
                                        key: `${key}.start`,
                                        labelTranslationKey: t(
                                            `${labelTranslationKey} Start`,
                                        ),
                                    }}
                                    control={control}
                                    arrayPath={arrayPath}
                                    arrayIndex={arrayIndex}
                                />
                            </span>
                            <span className="w-1/2">
                                <DynamicFormField
                                    field={{
                                        ...fieldProps.end,
                                        key: `${key}.end`,
                                        labelTranslationKey: t(
                                            `${labelTranslationKey} End`,
                                        ),
                                    }}
                                    control={control}
                                    arrayPath={arrayPath}
                                    arrayIndex={arrayIndex}
                                />
                            </span>
                        </div>
                    </div>
                ) : null;
            case 'textarea':
                return (
                    <textarea
                        id={key}
                        placeholder={placeholder}
                        className={className}
                        required={!!validators?.required}
                        {...field}
                        ref={ref as React.Ref<HTMLTextAreaElement>}
                    />
                );
            case 'checkbox':
                return (
                    <WizardFormCheckbox
                        type={type}
                        label={t(labelTranslationKey)}
                        errorMsg={errorMessage}
                        {...field}
                    />
                );
            case 'select':
                if ('options' in fieldProps && fieldProps.options) {
                    return (
                        <WizardSelect
                            label={t(labelTranslationKey)}
                            options={fieldProps.options}
                            errorMsg={errorMessage}
                            required={!!validators?.required}
                            {...field}
                        />
                    );
                }
                return null;
            default:
                return null;
        }
    };

    return renderField();
});
