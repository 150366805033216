import {
    ConditionalSpotlight,
    Headline,
    Input,
    LoadingSpinner,
    Paragraph,
} from '_atoms';
import { Table } from '_organisms/Table';
import { useCreateCaseMutation, useUsersQuery } from '_queries';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, Redirect } from 'react-router-dom';
import { routes } from '../../../../routes';
import { CaseSettings, CaseType, Step } from '../CaseNew.types';
import { NavigationBar } from '../NavigationBar';

export const CASE_TITLE_ALREADY_EXISTS = 'CASE_TITLE_ALREADY_EXISTS';

interface StepCaseAccessSettingsProps {
    caseType: CaseType;
    settings: CaseSettings;
    setStep: (nextStep: Step) => void;
    caseName: string;
    setCaseName: (caseName: string) => void;
    userIds: string[];
    setUserIds: React.Dispatch<React.SetStateAction<string[]>>;
}

export const StepCaseAccessSettings = (
    props: StepCaseAccessSettingsProps,
): React.ReactElement => {
    const {
        settings,
        caseType,
        setStep,
        caseName,
        setCaseName,
        userIds,
        setUserIds,
    } = props;

    const { t } = useTranslation();

    const usersQuery = useUsersQuery();
    const { mutate, isSuccess, isError, error, data, isLoading } =
        useCreateCaseMutation();

    const createNewCase = (): void => {
        // TODO: figure out why this is still needed
        localStorage.removeItem('oldTargetId');
        mutate({
            title: caseName,
            userIds,
            option: {
                value: caseType,
                props: {
                    ...settings,
                    keywords: settings.keywords
                        .map((keyword) => ({
                            value: keyword.value,
                            translations: (keyword.engTranslations ?? []).map(
                                (value) => ({
                                    value,
                                    language: 'en',
                                }),
                            ),
                        }))
                        .filter((k) => Boolean(k.value)),
                },
            },
        });
    };

    const isExistingCaseTitleError =
        (error as any)?.response?.data?.error?.message ===
        CASE_TITLE_ALREADY_EXISTS;

    useEffect(() => {
        if (isError && isExistingCaseTitleError) {
            document.body.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        }
    }, [isError, isExistingCaseTitleError]);

    if (isSuccess) {
        return (
            <Redirect
                to={generatePath(routes.newTargetData.path, {
                    caseId: data?.id,
                })}
            />
        );
    }

    return (
        <>
            <NavigationBar
                onBack={() => setStep(Step.CaseSettings)}
                onContinue={createNewCase}
                isContinueButtonDisabled={!caseName || isLoading}
                isBackButtonDisabled={isLoading}
                subTitle={t('caseNew.stepSubtitle.step3')}
            />
            <div className="container-fluid p-9 pt-0 xl:max-w-max-content">
                <div className="rounded-md bg-neutral-100 pt-5 px-6 pb-9 mb-8">
                    <div className="col-5">
                        <ConditionalSpotlight
                            targetName="onboardingCaseNewTitle"
                            condition={usersQuery.isSuccess}
                        >
                            <Input
                                required
                                value={caseName}
                                label={t('titleOfCase')}
                                placeholder={t('exampleTitle')}
                                onChange={({ target: { value } }) => {
                                    setCaseName(value);
                                }}
                            />
                        </ConditionalSpotlight>
                        {isExistingCaseTitleError && (
                            <div className="text-error-2 mt-2 ml-1 text-sm">
                                {t('caseNameMustBeUnique')}
                            </div>
                        )}
                    </div>
                </div>
                <div className="mb-6 rounded-md bg-neutral-100 pt-5 px-6 pb-9">
                    <Headline Level="h4" color="dark" weight="bold">
                        {t('assignUserToCase')}
                    </Headline>
                    <Paragraph
                        size="default"
                        lineHeight="default"
                        weight="normal"
                        color="default"
                        className="mb-10"
                    >
                        {t('assignUserToCaseSubtext')}
                    </Paragraph>
                    <div className="border border-gray-200 rounded-md">
                        <Table
                            headlines={[t('name'), t('email')]}
                            items={(usersQuery.data ?? []).map(
                                ({ id, firstname, lastname, email }) => ({
                                    id,
                                    name: `${firstname} ${lastname}`,
                                    email,
                                }),
                            )}
                            additions={{
                                checkbox: true,
                            }}
                            selectedItems={userIds}
                            setSelectedItems={setUserIds}
                        />
                        {usersQuery.isLoading && <LoadingSpinner />}
                        {usersQuery.isError && (
                            <Paragraph>{t('noResultHeadline')}</Paragraph>
                        )}
                        {usersQuery.isSuccess && !usersQuery.data?.length && (
                            <Paragraph>{t('noUsers')}</Paragraph>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};
