import { FC, ReactNode } from 'react';
import { Headline } from '_atoms';
import classnames from 'classnames';

export type PageLayoutProps = {
    title: string;
    subtitle?: string;
    headerActions?: ReactNode;
    headerSubActions?: ReactNode;
    className?: string;
};

export const PageLayoutHeader: FC<PageLayoutProps> = ({
    title,
    subtitle,
    headerActions,
    headerSubActions,
}) => {
    const headlines = subtitle ? (
        <div>
            <Headline Level="h3">{title}</Headline>
            <span className="text-base text-neutral-500">{subtitle}</span>
        </div>
    ) : (
        <Headline Level="h3">{title}</Headline>
    );

    const headerActionClassname = 'flex justify-between items-center space-x-5';

    if (!headerActions && !headerSubActions) {
        return headlines;
    }

    if (headerActions && !headerSubActions) {
        return (
            <>
                {headlines}
                {headerActions}
            </>
        );
    }

    if (!headerActions && headerSubActions) {
        return (
            <>
                {headlines}
                {headerSubActions}
            </>
        );
    }

    return (
        <>
            <div className={headerActionClassname}>
                {headlines}
                {headerActions}
            </div>
            {headerSubActions}
        </>
    );
};

export const headerActionClassname =
    'flex justify-between items-center space-x-5';

export const DefaultPageLayout: FC<PageLayoutProps> = (props) => {
    const { title, headerActions, headerSubActions, className, children } =
        props;

    return (
        <div
            className={classnames(
                'container-fluid p-9 xl:max-w-max-content relative print:px-0 print:py-4',
                className,
            )}
        >
            <div
                className={classnames(
                    title && 'pb-8',
                    title &&
                        headerActions &&
                        !headerSubActions &&
                        headerActionClassname,
                    headerSubActions && 'space-y-7',
                )}
            >
                <PageLayoutHeader {...props} />
            </div>
            {children}
        </div>
    );
};
