import React, { forwardRef, useState } from 'react';
import { FormType } from '../../../features/targets/TargetNew/forms/form.interface';
import { WizardInput } from '../Input/WizardInput';

export type AutocompleteOption = {
    label: string;
    value: NonNullable<FormType>;
};

type AutocompleteProps = {
    options: Array<AutocompleteOption>;
    onSelect: (value: NonNullable<FormType>) => void;
};

export const AutoComplete = forwardRef<HTMLInputElement, AutocompleteProps>(
    ({ options, onSelect }, ref) => {
        const [input, setInput] = useState('');
        const [suggestions, setSuggestions] =
            useState<Array<AutocompleteOption>>(options);
        const [showSuggestions, setShowSuggestions] = useState(false);

        const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            const value = e.target.value;
            setInput(value);
            if (value.length > 0) {
                const filteredSuggestions = options.filter((item) =>
                    item.value.toLowerCase().startsWith(value.toLowerCase()),
                );
                setSuggestions(filteredSuggestions);
            } else {
                setSuggestions(options);
            }
        };

        const handleFocus = () => {
            setShowSuggestions(true);
        };

        const handleSuggestionClick = (value: AutocompleteOption['value']) => {
            setInput(value);
            setShowSuggestions(false);
            onSelect(value);
        };

        return (
            <div className="flex flex-col items-center justify-center h-full relative z-2">
                <WizardInput
                    type="text"
                    value={input}
                    onChange={handleChange}
                    onFocus={handleFocus}
                    placeholder="Start typing..."
                    label="Add new detail"
                    ref={ref}
                >
                    {showSuggestions && suggestions.length > 0 && (
                        <ul className="absolute bg-neutral-50 text-base border-neutral-400 w-full rounded-md transition-all border-2 max-w-flow-text-base z-3">
                            {suggestions.map((suggestion) => (
                                <li
                                    key={suggestion.value}
                                    onClick={() => {
                                        handleSuggestionClick(suggestion.value);
                                    }}
                                    className="px-4 py-3.5 cursor-pointer hover:bg-primary-4/15 capitalize"
                                >
                                    {suggestion.label}
                                </li>
                            ))}
                        </ul>
                    )}
                </WizardInput>
            </div>
        );
    },
);

AutoComplete.displayName = 'AutoComplete';
