import { DetailedHTMLProps, forwardRef, InputHTMLAttributes } from 'react';
import classnames from 'classnames';

interface WizardFormCheckboxProps
    extends DetailedHTMLProps<
        InputHTMLAttributes<HTMLInputElement>,
        HTMLInputElement
    > {
    label?: string;
    errorMsg?: string;
}

export const WizardFormCheckbox = forwardRef<
    HTMLInputElement,
    WizardFormCheckboxProps
>((props, ref) => {
    const { label, errorMsg, required, children, ...rest } = props;

    return (
        <div className="w-full max-w-flow-text-base mx-auto relative">
            {label && (
                <label className="flex-row items-center" htmlFor={rest.id}>
                    {label}
                    {required && <span className="text-primary-4"> *</span>}
                </label>
            )}

            <div
                className={classnames(
                    'relative border rounded transition-all',
                    rest.disabled
                        ? 'bg-neutral-400'
                        : `cursor-pointer bg-neutral-50 hover:border-primary-4 hover:focus-within:border-primary-4 transition-colors`,
                    `w-6 h-6 flex flex-shrink-0 justify-center items-center`,
                    rest.value
                        ? `border-primary-4 focus-within:border-primary-4`
                        : 'border-neutral-400 focus-within:border-neutral-400',
                )}
            >
                <input
                    type="checkbox"
                    className="absolute cursor-pointer opacity-0 disabled:pointer-events-none disabled:cursor-default"
                    checked={!!rest.value}
                    ref={ref}
                    {...rest}
                />
                <svg
                    className={`fill-current hidden p-1 w-full h-full text-primary-4 pointer-events-none rounded`}
                    viewBox="0 0 20 20"
                >
                    <path d="M0 11l2-2 5 5L18 3l2 2L7 18z" />
                </svg>
            </div>
            {errorMsg && <div className="text-error-1">{errorMsg}</div>}
            {children}
        </div>
    );
});
WizardFormCheckbox.displayName = 'WizardFormCheckbox';
