import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { routes } from '../../../routes';
import { useCaseQuery, useTargetSelectedCandidatesQuery } from '_queries';
import { Card, Error, Headline, LoadingSpinner, Paragraph } from '_atoms';
import { Alert, ImageGalleryModal, Tabs } from '_molecules';
import { GoogleCandidateStatus } from '_types';
import { Tab } from '@headlessui/react';
import { LegacyStructuredCandidate } from '../../targets/TargetCandidates/LegacyStructuredCandidate';
import { LegacyUnstructuredCandidate } from '../../targets/TargetCandidates/LegacyUnstructuredCandidate';
import { getDefaultSelectedCandidatesTab } from '_utils';
import { CaseType } from '../../cases/CaseNew';

export const Candidates: FC = () => {
    const { t } = useTranslation();
    const { caseId, targetId } =
        useParams<{
            caseId: string;
            targetId: string;
        }>();

    const [googleCandidatesSelection, setGoogleCandidatesSelection] = useState<
        Record<string, GoogleCandidateStatus[]>
    >({});

    const [isGalleryOpened, setIsGalleryOpened] = useState(false);
    const [galleryImages, setGalleryImages] = useState<string[]>([]);

    const caseQuery = useCaseQuery(caseId);

    const {
        isError: candidatesError,
        data: {
            candidates,
            googleCandidates,
            wrongCandidates,
            likelyCandidates,
            possibleCandidates,
        } = {
            candidates: [],
            googleCandidates: [],
            allCandidates: [],
            wrongCandidates: [],
            likelyCandidates: [],
            possibleCandidates: [],
        },
    } = useTargetSelectedCandidatesQuery(
        caseId,
        targetId,
        caseQuery.data?.option?.option ?? CaseType.Essential,
    );

    const hasTradeRegisterData = (() => {
        return candidates?.some(
            (group) =>
                group.sources.includes('orbis') ||
                group.sources.includes('companyhouse'),
        );
    })();

    const hasCandidates = (() => {
        return (
            (candidates?.length ?? 0) > 0 || (googleCandidates?.length ?? 0) > 0
        );
    })();

    // initial selection
    useEffect(() => {
        if (googleCandidates?.length) {
            setGoogleCandidatesSelection(
                Object.fromEntries(
                    googleCandidates.map(({ groupId, candidates }) => [
                        groupId,
                        candidates.map((candidate) => ({
                            id: candidate.id,
                            status: candidate.status,
                            imageSelectionStatus: [],
                        })),
                    ]),
                ),
            );
        }
    }, [googleCandidates]);

    const handleImageGalleryOpen = ({ images }: { images: string[] }) => {
        setGalleryImages(images);
        setIsGalleryOpened(true);
    };

    const handleImageGalleryClose = () => {
        setIsGalleryOpened(false);
    };

    const defaultTabIndex = getDefaultSelectedCandidatesTab({
        likelyCandidates,
        possibleCandidates,
        wrongCandidates,
    });

    return (
        <div className="container-fluid p-9 pb-0 h-full flex flex-col xl:max-w-max-content">
            <Alert
                alertType="disclamer"
                message={t('inputDataDisclamer')}
                className="mt-4"
            />
            <div className="row">
                <div className="col-12">
                    <div className="row mb-6">
                        <Headline className="mt-6" Level="h3" color="dark">
                            {t('resolveTargetCandidatesHeadline')}
                        </Headline>
                    </div>
                    {candidatesError && (
                        <Error
                            headline={t('noResultHeadline')}
                            message={t('noResultText')}
                            target={{
                                link: `${routes.casesList.path}`,
                                text: t('cancelCreateTarget'),
                            }}
                        />
                    )}
                    {hasCandidates && !hasTradeRegisterData && (
                        <Card className="border-2 p-5 mb-6 text-center border-gray-200">
                            <Paragraph>
                                {t('noTradeRegisterDataText')}
                            </Paragraph>
                        </Card>
                    )}
                    {hasCandidates ? (
                        <Tabs
                            tabTitles={[
                                `${t('likelyCandidates')} (${
                                    likelyCandidates.length
                                })`,
                                `${t('possibleCandidates')} (${
                                    possibleCandidates.length
                                })`,
                                `${t('wrongCandidates')} (${
                                    wrongCandidates.length
                                })`,
                            ]}
                            defaultIndex={defaultTabIndex}
                        >
                            {[
                                likelyCandidates,
                                possibleCandidates,
                                wrongCandidates,
                            ].map((tab, i) => (
                                <Tab.Panel key={i} className="mt-6 space-y-6">
                                    <div className="grid grid-cols-1 4xl:grid-cols-2 gap-5">
                                        {tab.map((candidate) =>
                                            'info' in candidate ? (
                                                <LegacyStructuredCandidate
                                                    key={candidate.groupId}
                                                    {...candidate}
                                                    onStateChange={() => null}
                                                    onGalleryOpen={
                                                        handleImageGalleryOpen
                                                    }
                                                    disabled={true}
                                                />
                                            ) : (
                                                <LegacyUnstructuredCandidate
                                                    key={candidate.groupId}
                                                    {...candidate}
                                                    selectedCandidates={
                                                        googleCandidatesSelection
                                                    }
                                                    selectCandidates={() =>
                                                        null
                                                    }
                                                    disabled={true}
                                                />
                                            ),
                                        )}
                                    </div>
                                </Tab.Panel>
                            ))}
                        </Tabs>
                    ) : (
                        <LoadingSpinner
                            message={<span className="invisible">...</span>}
                        />
                    )}
                </div>
            </div>
            <ImageGalleryModal
                isOpen={isGalleryOpened}
                onClose={handleImageGalleryClose}
                images={galleryImages}
            />
        </div>
    );
};
