import { Card, TooltipContentV2, TooltipTriggerV2, TooltipV2 } from '_atoms';
import { SuspicionByCategory } from '_types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IoMdInformationCircleOutline } from 'react-icons/io';
import { UseFilters } from './useFilters';
import { SuspicionRiskLevel } from '_enums';
import { SuspicionBadge } from './SuspicionBadge';

interface SuspicionRiskProps {
    risk: SuspicionRiskLevel;
    suspicions: SuspicionByCategory[];
    setFilters: UseFilters['setFilters'];
    filters: UseFilters['filters'];
}

const riskToColor = {
    high: 'error-1',
    medium: 'warning-1',
    low: 'success-1',
};

export const SuspicionRisk = (
    props: SuspicionRiskProps,
): React.ReactElement => {
    const { risk, suspicions, setFilters, filters } = props;

    const { t } = useTranslation();

    const color = riskToColor[risk];

    const total = useMemo(
        () =>
            suspicions.reduce((total, suspicion) => total + suspicion.total, 0),
        [suspicions],
    );

    const confirmedTotal = useMemo(
        () =>
            suspicions.reduce(
                (total, suspicion) => total + suspicion.confirmedTotal,
                0,
            ),
        [suspicions],
    );

    const sortedSuspicions = suspicions.sort((a, b) => {
        const totalDifference = b.total - a.total;
        if (totalDifference !== 0) {
            return totalDifference;
        }

        return (a.label ?? '').localeCompare(b.label ?? '');
    });

    return (
        <div>
            <div className={`text-${color} flex font-semibold mb-2`}>
                <span className="mr-1">
                    {t(`riskAnalysis.suspicionRisk.${risk}.title`)}
                </span>
                <span className="text-neutral-500">
                    ({confirmedTotal}
                    <span className="font-light mx-1">/</span>
                    {total}
                    <label className="font-light ml-1">
                        {t('riskAnalysis.suspicionRisk.total')}
                    </label>
                    )
                </span>
            </div>
            <Card
                className={`border-${color} border-l-8 p-6 flex items-center justify-between gap-6`}
            >
                <div className="flex flex-wrap gap-3 flex-1">
                    {sortedSuspicions.map((suspicion) => {
                        const isSuspicionActive =
                            filters.selectedSuspicionId === suspicion.id &&
                            filters.risk === risk;

                        return (
                            <SuspicionBadge
                                key={suspicion.id}
                                className={
                                    isSuspicionActive
                                        ? 'pointer-events-none'
                                        : ''
                                }
                                isSelected={isSuspicionActive}
                                onClick={() => {
                                    setFilters({
                                        selectedSuspicionId: suspicion.id,
                                        risk,
                                    });
                                }}
                                name={t(
                                    `suspicionKeywords.${suspicion.label}`,
                                    {
                                        defaultValue: suspicion.name,
                                    },
                                )}
                                involvement={
                                    suspicion.involvement
                                        ? t(
                                              `suspicionItemInvolvement.${suspicion.involvement}`,
                                          )
                                        : undefined
                                }
                                total={suspicion.total}
                            />
                        );
                    })}
                </div>
                <TooltipV2 openDelay={500} placement="top-start">
                    <TooltipContentV2 className="max-w-72 z-20" type="primary">
                        {t(`riskAnalysis.suspicionRisk.${risk}.tooltip`)}
                    </TooltipContentV2>
                    <TooltipTriggerV2 asChild={true}>
                        <div className="text-primary-4">
                            <IoMdInformationCircleOutline />
                        </div>
                    </TooltipTriggerV2>
                </TooltipV2>
            </Card>
        </div>
    );
};
