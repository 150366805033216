import { useState } from 'react';
import { getQueryParam, replaceHistoryState } from '../../../hooks/useSearch';
import {
    SuspicionItemEntityType,
    SuspicionItemStatus,
    SuspicionRiskLevel,
} from '_enums';
import { SuspicionItemSource } from '../../../types/SuspicionItem';
import { useLocation, useHistory } from 'react-router-dom';

const FILTER_QUERY_PARAM = 'suspicionFilters';

export interface SuspicionFilters {
    risk?: SuspicionRiskLevel;
    selectedSuspicionId?: string;
    suspicionIds?: string[];
    contentTypes?: SuspicionItemEntityType[];
    sources?: SuspicionItemSource[];
    reviewStatuses?: SuspicionItemStatus[];
}

const VALID_CONTENT_TYPE = Object.values(SuspicionItemEntityType);
const VALID_REVIEW_STATUS = Object.values(SuspicionItemStatus);
const VALID_SOURCE = Object.values(SuspicionItemSource);
const VALID_RISKS = Object.values(SuspicionRiskLevel);

const toValidFilters = (filters: SuspicionFilters): SuspicionFilters => {
    const risk =
        filters.risk && VALID_RISKS.includes(filters.risk)
            ? filters.risk
            : undefined;

    const suspicionIds = Array.isArray(filters.suspicionIds)
        ? filters.suspicionIds.filter(String)
        : undefined;

    const selectedSuspicionId =
        typeof filters.selectedSuspicionId === 'string'
            ? filters.selectedSuspicionId
            : undefined;

    const contentTypes = Array.isArray(filters.contentTypes)
        ? filters.contentTypes.filter((contentType) =>
              VALID_CONTENT_TYPE.includes(contentType),
          )
        : undefined;

    const reviewStatuses = Array.isArray(filters.reviewStatuses)
        ? filters.reviewStatuses.filter((reviewStatus) =>
              VALID_REVIEW_STATUS.includes(reviewStatus),
          )
        : undefined;

    const sources = Array.isArray(filters.sources)
        ? filters.sources.filter((source) => VALID_SOURCE.includes(source))
        : undefined;

    return {
        ...(risk ? { risk } : {}),
        ...(selectedSuspicionId ? { selectedSuspicionId } : {}),
        ...(suspicionIds ? { suspicionIds } : {}),
        ...(contentTypes ? { contentTypes } : {}),
        ...(sources ? { sources } : {}),
        ...(reviewStatuses ? { reviewStatuses } : {}),
    };
};

export interface UseFilters {
    filters: SuspicionFilters;
    setFilters: (newFilters: SuspicionFilters) => void;
    clearFilters: () => void;
    redirectWithFilters: (
        pathname: string,
        newFilters: SuspicionFilters,
    ) => void;
}

export const useFilters = (): UseFilters => {
    const { search } = useLocation();
    const history = useHistory();

    const queryParams = getQueryParam(FILTER_QUERY_PARAM, search);

    const [_filters, _setFilters] = useState<SuspicionFilters>(() => {
        try {
            return toValidFilters(JSON.parse(queryParams));
        } catch {
            return {};
        }
    });

    const setFilters = (newFilters: SuspicionFilters) => {
        const nextFilters = toValidFilters({
            ..._filters,
            ...newFilters,
        });

        _setFilters(nextFilters);

        replaceHistoryState(
            JSON.stringify({ [FILTER_QUERY_PARAM]: nextFilters }),
        );
    };

    const clearFilters = () => {
        _setFilters({});
        replaceHistoryState(JSON.stringify({ [FILTER_QUERY_PARAM]: {} }));
    };

    const redirectWithFilters = (
        pathname: string,
        newFilters: SuspicionFilters,
    ): void => {
        const nextFilters = toValidFilters({
            ..._filters,
            ...newFilters,
        });
        const searchParams = new URLSearchParams({
            [FILTER_QUERY_PARAM]: JSON.stringify(nextFilters),
        });

        history.push({
            pathname,
            search: searchParams.toString(),
        });
    };

    return {
        filters: _filters,
        setFilters,
        clearFilters,
        redirectWithFilters,
    };
};
