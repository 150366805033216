import { forwardRef, HTMLAttributes, ReactNode, Ref } from 'react';
import { Paragraph } from '_atoms';
import { ReactComponent as Logo } from '../../../images/logo.svg';
import { nonProdDataTestId } from '_utils';

interface LoadingSpinnerProps extends HTMLAttributes<HTMLElement> {
    message?: ReactNode;
    height?: number;
    width?: number;
}

export const LoadingSpinner = forwardRef(function LoadingSpinner(
    { message, height = 30, width = 30 }: LoadingSpinnerProps,
    ref: Ref<HTMLElement>,
) {
    return (
        <figure
            className="flex flex-col items-center p-2 w-full"
            data-testid={nonProdDataTestId('loading icon')}
            ref={ref}
        >
            <Logo
                className="animate-spin-slow text-primary-4 fill-current"
                style={{ maxHeight: height, maxWidth: width }}
            />
            {message && (
                <figcaption>
                    <Paragraph color="blue">{message}</Paragraph>
                </figcaption>
            )}
        </figure>
    );
});
