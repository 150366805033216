import React from 'react';
import { useTranslation } from 'react-i18next';
import { MdCancel } from 'react-icons/md';
import { Paragraph } from '_atoms';
import { capitalize } from '_utils';
import { SocialMediaErrorPath } from '../../../services/dataService';
import type { SocialMediaErrors as Errors } from './useSocialMediaErrors';

interface SocialMediaErrorsProps {
    errors: Errors;
}

const errorPathToTranslationKey = {
    [SocialMediaErrorPath.FacebookUser]: 'user',
    [SocialMediaErrorPath.FacebookPosts]: 'posts',
    [SocialMediaErrorPath.FacebookFriends]: 'friends',
    [SocialMediaErrorPath.FacebookFollowing]: 'following',
    [SocialMediaErrorPath.FacebookFollowers]: 'followers',
    [SocialMediaErrorPath.FacebookPost]: 'post',
    [SocialMediaErrorPath.FacebookVideo]: 'video',
    [SocialMediaErrorPath.FacebookPhoto]: 'photo',
    [SocialMediaErrorPath.TwitterUser]: 'user',
    [SocialMediaErrorPath.TwitterTweets]: 'tweets',
    [SocialMediaErrorPath.TwitterFollowing]: 'following',
    [SocialMediaErrorPath.TwitterFollowers]: 'followers',
    [SocialMediaErrorPath.TwitterTweet]: 'tweet',
    [SocialMediaErrorPath.LinkedInUser]: 'user',
    [SocialMediaErrorPath.LinkedInPosts]: 'posts',
    [SocialMediaErrorPath.LinkedInPost]: 'post',
    [SocialMediaErrorPath.InstagramUser]: 'user',
    [SocialMediaErrorPath.InstagramFollowing]: 'following',
    [SocialMediaErrorPath.InstagramFollowers]: 'followers',
    [SocialMediaErrorPath.InstagramPosts]: 'posts',
    [SocialMediaErrorPath.InstagramPost]: 'post',
    [SocialMediaErrorPath.InstagramReel]: 'reel',
    [SocialMediaErrorPath.InstagramStory]: 'story',
    [SocialMediaErrorPath.XingUser]: 'user',
    [SocialMediaErrorPath.XingContacts]: 'contacts',
};

export const SocialMediaErrors = ({
    errors,
}: SocialMediaErrorsProps): React.ReactElement => {
    const { t } = useTranslation();

    return (
        <div className="flex p-4 rounded-md border border-error-2 bg-red-100 mt-2 mr-2">
            <div className="text-error-2 mr-3.5 flex">
                <MdCancel />
            </div>
            <div>
                {[...errors.entries()].map(([category, errorsPerPlatform]) => (
                    <div key={category} className="mb-2">
                        <Paragraph
                            size="default"
                            weight="normal"
                            lineHeight="default"
                            className="text-error-1"
                        >
                            {t(`socialMediaErrors.${category}`)}
                        </Paragraph>
                        {[...errorsPerPlatform.entries()].map(
                            ([platform, paths]) => (
                                <div key={platform} className="pl-6">
                                    <Paragraph
                                        size="default"
                                        weight="normal"
                                        lineHeight="default"
                                        className="text-error-1"
                                    >
                                        <b>{`${capitalize(platform)}: `}</b>
                                        {[...paths.values()]
                                            .map((path) =>
                                                t(
                                                    `socialMediaErrors.path.${errorPathToTranslationKey[path]}`,
                                                ),
                                            )
                                            .join(', ')}
                                    </Paragraph>
                                </div>
                            ),
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};
