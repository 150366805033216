import { FC, useCallback, useEffect, useState } from 'react';
import {
    generatePath,
    Redirect,
    useHistory,
    useParams,
} from 'react-router-dom';
import { UserInputTarget } from '@indicium/common';
import { LoadingSpinner } from '_atoms';
import {
    useCreateTargetMutation,
    useDeleteTargetMutationV2 as useDeleteTargetMutation,
    useTargetInputQuery,
} from '../../../hooks/queries';
import { sanitizeInput } from '@indicium/common/src/utils';
import { ProcessTarget } from '@indicium/common/src/types/Target/TargetTypes';
import { useMutationErrors } from '../../../hooks/useMutationErrors';
import { useUserQuota } from '../../../hooks/useUserQuota';
import { TargetForm } from '../TargetForm';
import { routes } from '../../../routes';
import { TargetFormSchema } from '../../../schemas/targetFormSchema';

const changeTarget = async (
    // eslint-disable-next-line
    deleteTarget: any,
    // eslint-disable-next-line
    createTarget: any,
    caseId: string,
    targetId: string,
    // eslint-disable-next-line
    data: any,
) => {
    await deleteTarget({ caseId, targetId });
    createTarget({ caseId, data });
};

export const TargetNew: FC = () => {
    const history = useHistory();

    const { caseId } = useParams<{ caseId: string }>();

    const inputTargetData = history.location.state as
        | { data: ProcessTarget; images: string[] }
        | undefined;
    const inputTarget = inputTargetData?.data;
    const cvImages = inputTargetData?.images;

    const oldTargetId = localStorage.getItem('oldTargetId');
    const oldTarget = useTargetInputQuery(caseId, oldTargetId || '');
    const [oldTargetLoaded, setOldTargetLoaded] = useState('');

    const { data: target, ...createTargetMutation } = useCreateTargetMutation();

    const { errors: apiErrors } = useMutationErrors({
        mutations: { createTargetMutation },
    });

    const {
        isLoading: isDeleteTargetLoading,
        isError: _isDeleteTargetError,
        ...deleteTargetMutation
    } = useDeleteTargetMutation();

    const { quota } = useUserQuota();

    useEffect(() => {
        if (quota && quota.remaining <= 0) {
            history.push(generatePath(routes.caseShow.path, { caseId }));
        }
    }, [quota, caseId, history]);

    useEffect(() => {
        if (oldTarget.data) {
            setOldTargetLoaded(localStorage.getItem('oldTargetId') || '');
            localStorage.removeItem('oldTargetId');
        }
    }, [oldTarget]);

    const handleSubmit = useCallback(
        // TODO: IND-945 Fix type issues and set type of values to Yup.TypeOf<typeof UserInputTargetSchema>
        async (values: unknown) => {
            // TODO: IND-945 Remove the cast. Related to "Make this a Yup.SchemaOf<UserInputTargetSchema>" todo in UserInputTargetSchema.ts
            const data = sanitizeInput<UserInputTarget>(
                (await TargetFormSchema.validate(values)) as UserInputTarget,
            );

            if (data) {
                if (oldTargetLoaded) {
                    if (
                        JSON.stringify(values) ===
                        JSON.stringify(oldTarget.data)
                    ) {
                        // skip create target and navigate to target candidates
                        history.push(
                            generatePath(routes.targetShow.path, {
                                caseId,
                                targetId: oldTargetLoaded,
                            }),
                        );
                    } else {
                        changeTarget(
                            deleteTargetMutation.mutateAsync,
                            createTargetMutation.mutate,
                            caseId,
                            oldTargetLoaded,
                            data,
                        );
                    }
                } else {
                    createTargetMutation.mutate({
                        caseId,
                        data,
                        cvImages,
                    });
                }
            }
        },
        [
            oldTarget.data,
            oldTargetLoaded,
            caseId,
            createTargetMutation,
            deleteTargetMutation,
            history,
            cvImages,
        ],
    );

    if (createTargetMutation.isSuccess) {
        return (
            <Redirect
                to={generatePath(routes.targetShow.path, {
                    caseId,
                    targetId: target?.id,
                })}
            />
        );
    }

    if (createTargetMutation.isError) {
        console.error('error in createTarget: ', createTargetMutation.error);
    }

    return (
        <>
            <TargetForm
                targetInput={inputTarget ?? oldTarget.data}
                onSubmit={handleSubmit}
                isSubmitting={createTargetMutation.isLoading}
                apiErrors={apiErrors}
                showBackButton
            />
            {isDeleteTargetLoading && (
                <div className="z-20 inset-0 absolute bg-black/50 flex justify-center items-center text-neutral-400 pointer-events-none">
                    <LoadingSpinner
                        message={<span className="invisible">...</span>}
                    />
                </div>
            )}
        </>
    );
};
