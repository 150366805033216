import {
    MixedTargetCandidateGroup,
    TargetSelectedCandidates,
} from '../../../services/dataService';

export const groupCandidatesResult = <T extends TargetSelectedCandidates>(
    candidatesResult: T,
): T => {
    const isWrongCandidate = (candidate: MixedTargetCandidateGroup) =>
        ('pre_checked' in candidate && candidate.pre_checked === false) ||
        (candidate.score !== undefined &&
            candidate.score <= 0 &&
            !('pre_checked' in candidate));

    const isLikelyCandidate = (candidate: MixedTargetCandidateGroup) =>
        ('pre_checked' in candidate && candidate.pre_checked === true) ||
        (candidate.score !== undefined &&
            candidate.score > 1 &&
            !('pre_checked' in candidate));

    const isPossibleCandidate = (candidate: MixedTargetCandidateGroup) =>
        !isWrongCandidate(candidate) && !isLikelyCandidate(candidate);

    const wrongCandidates: MixedTargetCandidateGroup[] = [];
    const likelyCandidates: MixedTargetCandidateGroup[] = [];
    const possibleCandidates: MixedTargetCandidateGroup[] = [];

    candidatesResult.allCandidates.forEach((candidate) => {
        if (isWrongCandidate(candidate)) {
            wrongCandidates.push(candidate);
        }
        if (isLikelyCandidate(candidate)) {
            likelyCandidates.push(candidate);
        }
        if (isPossibleCandidate(candidate)) {
            possibleCandidates.push(candidate);
        }
    });

    return {
        ...candidatesResult,
        wrongCandidates,
        likelyCandidates,
        possibleCandidates,
    };
};
