import axios from 'axios';
import { useQuery, UseQueryResult } from 'react-query';
import { backendBaseUrl } from '../../backendConfig.json';
import { Target, TargetStatus } from '../../services/dataService';

const fetchTarget = async (
    caseId?: string,
    targetId?: string,
): Promise<Target> =>
    (
        await axios.get(
            `https://${backendBaseUrl}/cases/${caseId}/targets/${targetId}`,
        )
    ).data;

export const useTargetQuery = (
    queryKey: string,
    caseId?: string,
    targetId?: string,
): UseQueryResult<Target> =>
    useQuery(
        [queryKey, 'target', caseId, targetId],
        // TODO: undo this once the new target creation form is complete
        () =>
            // TODO: undo this once the new target creation form is complete
            targetId === 'new' || targetId === 'newV2'
                ? undefined
                : fetchTarget(caseId, targetId),
        {
            refetchInterval: (data) => {
                if (
                    data !== undefined &&
                    [
                        TargetStatus.Completed,
                        TargetStatus.CompletedWithIrregularities,
                        TargetStatus.CandidateSelectionPending,
                    ].indexOf(data.status) !== -1
                ) {
                    return false; // don't refetch if we're waiting on user input
                }

                if (
                    data !== undefined &&
                    data.status === TargetStatus.HasInitialProfile
                ) {
                    return 300000; // refetch every 5 minutes if we're waiting on report to finish
                }

                return 3000; // refetch every 3 seconds if we're actively waiting
            },
            enabled: !!targetId && targetId !== 'new' && !!caseId,
            cacheTime: 0,
            refetchOnWindowFocus: false,
        },
    );
