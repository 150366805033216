import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Highlight, Paragraph, SocialProfilePictureBubble } from '_atoms';
import { BaseCard } from '_organisms';
import { formatDateTime, getCdnUrl } from '_utils';
import { SocialAccountPostArticle } from '../../../services/searchService';
import { CaseData, Target } from '../../../services/dataService';
import { EntitySuspicion } from '_types';
import { HighlightChunks } from '../../_atoms/Highlight/types';
import { buildHighlightChunks } from '../../_atoms/Highlight/public-utils';
import { FiCalendar, FiHeart } from 'react-icons/fi';
import { normalizeUrl } from '../../_atoms/ResultTileDataColumns/LinkData';

interface SocialMediaSmallCardProps {
    data: SocialAccountPostArticle;
    query?: string;
    hideArticleType?: boolean;
    score?: number;
    status?: string;
    suspicionItemId?: string;
    targetData?: Target;
    caseData?: CaseData;
    suspicionItems?: Array<EntitySuspicion>;
    highlight?: HighlightChunks;
    indicators: string[];
}

export const SocialMediaSmallCard: FC<SocialMediaSmallCardProps> = ({
    data,
    query,
    hideArticleType = false,
    suspicionItems,
    indicators,
    caseData,
    ...props
}) => {
    const { targetData } = props;
    const { t } = useTranslation();

    const isTaggedPost = data.accountUserId !== data.postProfileId;

    const highlight = buildHighlightChunks({
        searchQuery: query,
        indicators,
        target: targetData,
        caseKeywords: caseData?.keywords,
    });

    return (
        <BaseCard
            entityType="socialPost"
            subtitle={isTaggedPost ? t('socialMedia.tagged') : ''}
            title={data.postProfileName}
            titleIcon={
                <SocialProfilePictureBubble
                    data={{
                        imageUrl: isTaggedPost
                            ? ''
                            : getCdnUrl(data.accountProfileImageCdnUrl),
                        network: data.accountType?.toLowerCase(),
                    }}
                    size="small"
                />
            }
            headlineLevel="h5"
            titleLink={normalizeUrl(data.postUrl)}
            item={data}
            hideArticleType={hideArticleType}
            suspicionItems={suspicionItems}
            highlight={highlight}
            {...props}
        >
            <div className="px-4 pb-4 flex flex-col flex-1">
                <div className="flex-1 flex flex-col">
                    <div className="flex flex-col flex-grow overflow-y-auto p-3 rounded-md bg-gray-100 border border-neutral-300 h-72">
                        {data.postImageCdnUrl && (
                            <img
                                src={getCdnUrl(data.postImageCdnUrl)}
                                alt="social post"
                                className="pb-3 w-full object-cover"
                            />
                        )}
                        <Paragraph
                            className="whitespace-pre-wrap"
                            color="dark"
                            style={{ overflowWrap: 'anywhere' }}
                        >
                            <Highlight
                                searchWords={highlight}
                                textToHighlight={data.postBody ?? ''}
                            />
                        </Paragraph>
                    </div>
                </div>
                <div className="flex items-center justify-between mt-4">
                    <Paragraph
                        className="flex items-center"
                        color="mediumDark"
                        size="default"
                    >
                        <FiHeart size="15" className="mr-2" />
                        <Highlight
                            searchWords={highlight}
                            textToHighlight={
                                data.postLikeCount?.toLocaleString() ??
                                t('noData')
                            }
                        />
                    </Paragraph>
                    <Paragraph
                        className="flex items-center"
                        color="mediumDark"
                        size="default"
                    >
                        <FiCalendar size="15" className="mr-2" />
                        <Highlight
                            searchWords={highlight}
                            textToHighlight={
                                formatDateTime(
                                    data.postDatePublished?.toString() ?? '',
                                ) ?? t('noData')
                            }
                        />
                    </Paragraph>
                </div>
            </div>
        </BaseCard>
    );
};
