import { FC } from 'react';
import { IconContext } from 'react-icons';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Layout } from './components/Layout/Layout';
import { Router } from './Router';
import './index.css';
import { UserProvider } from './context/User';
import { Tracking } from './features/tracking/Tracking';
import { LocalStorageProvider } from './context/LocalStorageProvider';
import { MenuProvider } from './context/Menu';
import { SpotlightProvider } from './context/Spotlight';

const queryClient = new QueryClient();

const App: FC = () => (
    <QueryClientProvider client={queryClient}>
        <SpotlightProvider>
            <MenuProvider>
                <UserProvider>
                    <BrowserRouter>
                        <Tracking />
                        <IconContext.Provider
                            value={{
                                className: 'inline-block text-2xl',
                            }}
                        >
                            <Layout>
                                <LocalStorageProvider>
                                    <Router />
                                </LocalStorageProvider>
                            </Layout>
                        </IconContext.Provider>
                    </BrowserRouter>
                </UserProvider>
            </MenuProvider>
        </SpotlightProvider>
    </QueryClientProvider>
);

export default App;
