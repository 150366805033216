import { FormSchema } from './form.interface';
import {
    ContactType,
    DateStr,
    DynamicFormValue,
    SocialMediaType,
    UserInputTarget,
} from '@indicium/common';
import { uniqueId } from 'lodash';
import { ProcessTarget } from '@indicium/common/src/types/Target/TargetTypes';
import moment from 'moment/moment';

export const mapUserInputTargetToFormSchema = (
    input: ProcessTarget,
): FormSchema => {
    const formSchema: FormSchema = {
        firstName: input.firstname,
        lastName: input.lastname,
        middleName: input.middlename,
        title: input.title,
        gender: input.gender,
        dateOfBirth: input.dateOfBirth ? input.dateOfBirth.start : '',
        countryOfResidence: input.countryOfResidence,
        nationalities: input.nationalities || [],
        placeOfBirth: input.placeOfBirth,
        countryOfBirth: input.countryOfBirth,
        contact: {
            email: '',
            phone: '',
            website: '',
        },
        jobs: [],
        education: [],
        socialMediaProfiles: {},
        images: input.images ?? [],
        relatedPersons: input.relatedPersons ?? [],
        websites: input.websites ?? [],
        organizations: input.organizations ?? [],
        topics: input.topics ?? [],
        locations: input.locations ?? [],
        //TODO: push input.nickname into nicknames if it exists
        nicknames: input.nicknames ?? [],
    };

    // Map contact
    if (input.contact) {
        input.contact.forEach((contact) => {
            switch (contact.type) {
                case ContactType.email:
                    formSchema.contact.email = contact.value;
                    break;
                case ContactType.phone:
                    formSchema.contact.phone = contact.value;
                    break;
                case ContactType.website:
                    formSchema.contact.website = contact.value;
                    break;
            }
        });
    }

    formSchema.jobs = (input.jobs ?? []).map((job) => ({
        jobTitle: job.title,
        jobDate:
            job.date && typeof job.date === 'object'
                ? `${job.date.start} - ${job.date.end}`
                : job.date,
        selfEmployed: job.selfEmployed,
        companyName: job.company?.name || '',
        companyWebsite: job.company?.website,
        companyVatNumber: job.company?.vatNumber,
        companyCommercialRegisterNumber: job.company?.commercialRegisterNumber,
        companyCity: job.company?.city,
    }));

    // Map education
    formSchema.education = (input.education ?? []).map((edu) => ({
        title: edu.title,
        type: edu.type,
        date:
            edu.date && typeof edu.date === 'object'
                ? {
                      start: new Date(edu.date.start),
                      end: new Date(edu.date.end),
                  }
                : {
                      start: new Date(edu.date as string),
                      end: new Date(edu.date as string),
                  },
        institutionName: edu.institution?.name,
        institutionLocation: edu.institution?.location,
    }));

    // Map social media profiles
    (input.socialMediaProfiles ?? []).forEach((profile) => {
        if (Object.values(SocialMediaType).includes(profile.type)) {
            formSchema.socialMediaProfiles[profile.type] = profile.value;
        }
    });

    return formSchema;
};

type MappableField = keyof Pick<
    UserInputTarget,
    | 'relatedPersons'
    | 'websites'
    | 'organizations'
    | 'topics'
    | 'locations'
    | 'nicknames'
>;

const fieldsToMap: MappableField[] = [
    'relatedPersons',
    'websites',
    'organizations',
    'topics',
    'locations',
    'nicknames',
];

const mapArrayWithId = (array: any[]): DynamicFormValue<string>[] =>
    array.map((item) => ({
        id: uniqueId(),
        value: item.value,
        status: item.status,
    }));

export const mapFormSchemaToUserInputTarget = (
    formData: FormSchema,
): UserInputTarget => {
    const userInputTarget: UserInputTarget = {
        firstname: formData.firstName,
        lastname: formData.lastName,
        middlename: formData.middleName,
        title: formData.title,
        gender: formData.gender,
        dateOfBirth: formData.dateOfBirth
            ? moment(formData.dateOfBirth).format('DD.MM.YYYY')
            : undefined,
        countryOfResidence: formData.countryOfResidence,
        nationalities: formData.nationalities,
        placeOfBirth: formData.placeOfBirth,
        countryOfBirth: formData.countryOfBirth,
        contact: [],
        jobs: [],
        education: [],
        socialMediaProfiles: [],
        images: formData.images,

        relatedPersons: formData.relatedPersons,
        websites: formData.websites,
        organizations: formData.organizations,
        topics: formData.topics,
        locations: formData.locations,
        nicknames: formData.nicknames,
    };

    fieldsToMap.forEach((field) => {
        userInputTarget[field] = mapArrayWithId(formData[field]);
    });

    // Map contact Data
    // TODO: DRY wins possible later?
    if (formData.contact.email) {
        userInputTarget.contact?.push({
            type: ContactType.email,
            value: formData.contact.email,
        });
    }
    if (formData.contact.phone) {
        userInputTarget.contact?.push({
            type: ContactType.phone,
            value: formData.contact.phone,
        });
    }
    if (formData.contact.website) {
        userInputTarget.contact?.push({
            type: ContactType.website,
            value: formData.contact.website,
        });
    }

    // Map jobs
    userInputTarget.jobs = formData.jobs.map((job) => ({
        title: job.jobTitle,
        date: job.jobDate,
        selfEmployed: job.selfEmployed,
        company: {
            name: job.companyName,
            website: job.companyWebsite,
            vatNumber: job.companyVatNumber,
            commercialRegisterNumber: job.companyCommercialRegisterNumber,
            country: job.companyCountry,
            city: job.companyCity,
        },
    }));

    // Map education
    userInputTarget.education = formData.education.map((edu) => ({
        title: edu.title,
        type: edu.type,
        date:
            // TODO: DRY wins possible later?
            edu?.date && edu?.date
                ? {
                      start: edu.date.start.toDateString() as DateStr,
                      end: edu.date.end.toDateString() as DateStr,
                  }
                : undefined,
        institution: {
            name: edu.institutionName,
            location: edu.institutionLocation,
        },
    }));

    // Map social media profiles
    userInputTarget.socialMediaProfiles = Object.entries(
        formData.socialMediaProfiles,
    )
        .filter(([_key, value]) => value !== undefined && value !== '')
        .map(([key, value]) => ({
            type: key as SocialMediaType,
            value: value as string,
        }));

    return userInputTarget;
};
