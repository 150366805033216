import { isNeitherNullNorUndefined, replaceUmlauts } from '_utils';

import { HighlightChunks, HighlightType, SearchTerms } from './types';

// define priority of highlighted categories
// a higher value means higher priority
const mapTypeToPriority = (type: HighlightType): number => {
    switch (type) {
        case HighlightType.SearchQuery:
            return 99;
        case HighlightType.TargetPerson:
            return 10;
        case HighlightType.SuspicionKeywords:
            return 6;
        case HighlightType.TargetCompanies:
            return 5;
        case HighlightType.CaseKeywords:
            return 4;
        default:
            return 0;
    }
};

const mapTypeToColor = (type: HighlightType): string => {
    switch (type) {
        case HighlightType.SearchQuery:
            return 'bg-yellow-200';
        case HighlightType.SuspicionKeywords:
            return 'bg-primary-4';
        case HighlightType.TargetPerson:
            return 'bg-success-1';
        case HighlightType.TargetCompanies:
            return 'bg-blue-100';
        case HighlightType.CaseKeywords:
            return 'bg-error-2';
        default:
            return '';
    }
};

export const getColorClass = (
    searchTerms: SearchTerms,
    component: unknown,
): string => {
    if (typeof component !== 'string') {
        return '';
    }

    // since the search were already sorted, we can just return the first match
    const lowerCaseWord = component.toLowerCase();
    const highestPriorityType = searchTerms.find(({ words }) =>
        words.some((word) => {
            if (typeof word === 'string') {
                return word.toLowerCase() === lowerCaseWord;
            }

            return word.test(lowerCaseWord);
        }),
    );

    if (highestPriorityType?.type === undefined) {
        return '';
    }

    return mapTypeToColor(highestPriorityType.type);
};

function escapeRegExp(str: string): string {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

// adding this as a separate function to allow for pre-processing of the search terms when they are reused
// e.g. in the SearchEngineItem.tsx where the same set of search terms is used for multiple Highlight instances
export const computeSearchWords = (
    searchWords?: HighlightChunks,
): SearchTerms => {
    return Array.from(Object.entries(searchWords ?? {}))
        .map(([type, _words]) => {
            const words = _words
                .map((untrimmedWord) => {
                    if (typeof untrimmedWord !== 'string') {
                        return [untrimmedWord];
                    }

                    if (!untrimmedWord) {
                        return [];
                    }

                    const escapedWord = escapeRegExp(untrimmedWord.trim());

                    const replacedUmlauts = replaceUmlauts(escapedWord);

                    return [
                        escapedWord.toLowerCase(),
                        escapedWord !== replacedUmlauts
                            ? replacedUmlauts.toLowerCase()
                            : null,
                    ];
                })
                .flat()
                .filter(isNeitherNullNorUndefined);

            return <SearchTerms[number]>{
                type: type as HighlightType,
                words,
            };
        })
        .sort((a, b) => mapTypeToPriority(b.type) - mapTypeToPriority(a.type));
};
