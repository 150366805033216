import { FC, useCallback } from 'react';
import { Breadcrumbs } from '_atoms';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import { useUserState } from '../../context/User';
import { Breadcrumb } from '../_atoms/Breadcrumbs/Breadcrumbs';
import { useCaseQuery, useTargetQuery } from '_queries';
import { useLocation } from 'react-router';
import { USER_GROUP_ADMINISTRATORS } from '../../services/authenticationService';
import { routes } from '../../routes';
import { MdOutlineHome } from 'react-icons/md';

type CaseToProfileBreadcrumbsProps = {
    className?: string;
};

export const CaseToProfileBreadcrumbs: FC<CaseToProfileBreadcrumbsProps> = ({
    className,
}) => {
    const { pathname } = useLocation();
    const { t } = useTranslation();

    const [{ initialRoute, userRoles }] = useUserState();

    const targetPageMatch = useRouteMatch(routes.targetShow.path);

    const casePageMatch = useRouteMatch({
        path: routes.caseShow.path,
    });

    const {
        caseId,
        targetId,
    }: {
        caseId?: string;
        targetId?: string;
    } = targetPageMatch?.params ?? casePageMatch?.params ?? {};

    const { data: caseData } = useCaseQuery(caseId);

    const { data: targetData } = useTargetQuery(
        'caseToProfile',
        caseId,
        targetId,
    );

    const createBreadcrumbs = useCallback(() => {
        const breadcrumbs: Breadcrumb[] = [
            {
                label: (
                    <MdOutlineHome
                        width="16"
                        height="16"
                        className="text-primary-4"
                    />
                ),
                route: initialRoute,
            },
        ];

        if (pathname.startsWith('/change-password')) {
            return breadcrumbs.concat([
                {
                    label: t('changePassword'),
                },
            ]);
        }

        if (pathname.startsWith('/profile')) {
            return breadcrumbs.concat([
                {
                    label: t('editProfile'),
                },
            ]);
        }

        if (pathname.startsWith('/cases')) {
            breadcrumbs.push({
                label: t('cases'),
                route: '/cases',
            });
        }

        if (
            pathname.startsWith('/users') &&
            userRoles.includes(USER_GROUP_ADMINISTRATORS)
        ) {
            breadcrumbs.push({
                label: t('users'),
                route: '/users',
            });
        }

        if (caseId) {
            breadcrumbs.push(
                caseId !== 'new'
                    ? {
                          label: caseData?.title || t('position'),
                          route: targetId ? `/cases/${caseId}` : undefined,
                      }
                    : {
                          label: t('createNewCase'),
                      },
            );
        }

        if (targetId) {
            const targetName = targetData
                ? `${targetData.firstname} ${targetData.lastname}`
                : '';
            breadcrumbs.push({
                label:
                    // TODO: undo this once the new target creation form is complete
                    targetId === 'new' || targetId === 'newV2'
                        ? t('createNewTarget')
                        : targetName,
            });
        }

        return breadcrumbs;
    }, [
        caseData?.title,
        caseId,
        initialRoute,
        pathname,
        t,
        targetData,
        targetId,
        userRoles,
    ]);

    return (
        <Breadcrumbs breadcrumbs={createBreadcrumbs()} className={className} />
    );
};
