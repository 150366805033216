import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { DynamicFormField } from './DynamicFormField';
import { DynamicFormProps, FormSchema } from './form.interface';
import { Button } from '_atoms';
import { useTranslation } from 'react-i18next';

const DynamicForm: React.FC<DynamicFormProps> = ({
    fields,
    onSubmit,
    submitButtonText = 'confirm',
    submitButtonClassName = '',
    defaultValues,
    parentKey,
    arrayPath,
    arrayIndex,
}) => {
    const { t } = useTranslation();
    const { handleSubmit, control, reset } = useForm<Partial<FormSchema>>({
        defaultValues,
        criteriaMode: 'all',
        mode: 'onChange',
    });

    useEffect(() => {
        reset(defaultValues);
    }, [arrayIndex, defaultValues, reset]);

    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col items-center justify-center h-full gap-4"
        >
            {fields.map((field) => {
                const fullKey = parentKey
                    ? `${parentKey}.${field.key}`
                    : field.key;

                return (
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    <DynamicFormField
                        key={fullKey}
                        field={{ ...field, key: fullKey }}
                        control={control}
                        arrayPath={arrayPath}
                        arrayIndex={arrayIndex}
                    />
                );
            })}
            <div>
                <Button level="primaryGhost">{t('cancel')}</Button>
                <Button type="submit" className={submitButtonClassName}>
                    {t(submitButtonText)}
                </Button>
            </div>
        </form>
    );
};

export default DynamicForm;
