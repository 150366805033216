import React from 'react';
import { ArticlePreviewCard } from './components/ArticlePreviewCard';
import { PressArticle } from '../../../../../services/searchService';
import { SuspicionArticle } from '_types';

interface PressProps {
    onClick: () => void;
    isActive: boolean;
    article: PressArticle;
    indicators: SuspicionArticle['indicators'];
    suspicionsCount: number;
}

export const Press = (props: PressProps): React.ReactElement => {
    const { onClick, isActive, article, indicators, suspicionsCount } = props;

    return (
        <ArticlePreviewCard
            onClick={onClick}
            isActive={isActive}
            title={article.title}
            type="press"
            suspicionsCount={suspicionsCount}
        >
            <div className="text-sm line-clamp-3">
                {indicators.map(({ indicator }) => indicator.value).join('\n')}
            </div>
        </ArticlePreviewCard>
    );
};
