import { SuspicionArticle } from '_types';
import React from 'react';
import { Press } from './Press';
import { SuspicionItemEntityType } from '_enums';
import { SearchEngine } from './SearchEngine';
import { Company } from './Company';
import { SocialMediaPost } from './SocialMediaPost';
import {
    HighlightChunks,
    IndicatorsWithTooltip,
} from '../../../../../components/_atoms/Highlight/types';

interface ArticleContentProps {
    article: SuspicionArticle;
    highlightChunks: HighlightChunks;
    indicators?: IndicatorsWithTooltip[];
}

export const ArticleContent = (
    props: ArticleContentProps,
): React.ReactElement => {
    const { article, highlightChunks, indicators } = props;

    const indicatorTooltips = new Map(
        indicators?.map((i) => [i.highlightText, i.tooltipForText]),
    );

    return (
        <>
            {article.entity.type === SuspicionItemEntityType.Press && (
                <Press
                    article={article.entity.value}
                    highlightChunks={highlightChunks}
                    tooltips={indicatorTooltips}
                />
            )}
            {article.entity.type === SuspicionItemEntityType.GoogleResult && (
                <SearchEngine
                    article={article.entity.value}
                    highlightChunks={highlightChunks}
                    tooltips={indicatorTooltips}
                />
            )}
            {article.entity.type === SuspicionItemEntityType.TargetCompany && (
                <Company article={article.entity.value} />
            )}
            {article.entity.type ===
                SuspicionItemEntityType.SocialMediaPost && (
                <SocialMediaPost
                    article={article.entity.value}
                    highlightChunks={highlightChunks}
                />
            )}
        </>
    );
};
