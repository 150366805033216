import React from 'react';
import { Highlight } from '_atoms';
import {
    HighlightChunks,
    IndicatorTooltip,
} from '../../../../../../components/_atoms/Highlight/types';

interface FullTextProps {
    title?: string;
    text?: string;
    highlight?: HighlightChunks;
    tooltips?: IndicatorTooltip;
}

export const FullText = (props: FullTextProps): React.ReactElement => {
    const { title, text, highlight, tooltips } = props;

    return (
        <div className="font-jost text-font-dark">
            {Boolean(title) && (
                <div className="font-bold mb-3 text-lg">{title}</div>
            )}
            {Boolean(text) && Boolean(highlight) ? (
                <Highlight
                    searchWords={highlight}
                    textToHighlight={text}
                    tooltips={tooltips}
                />
            ) : (
                <p className="mb-6 text-sm whitespace-pre-wrap">{text}</p>
            )}
        </div>
    );
};
