import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { WizardInput } from '../Input/WizardInput';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { MdClose, MdSearch } from 'react-icons/md';

export type AutocompleteOption = {
    label: string;
    value: string;
};

type AutocompleteProps = {
    options: Array<AutocompleteOption>;
    onChange: (value?: string) => void;
    value?: string;
    placeholder?: string;
    label?: string;
    disabled?: boolean;
};

export const AutocompleteV2 = forwardRef<HTMLInputElement, AutocompleteProps>(
    (props, ref) => {
        const { options, onChange, value, placeholder, label, disabled } =
            props;

        const [input, setInput] = useState('');
        const [suggestions, setSuggestions] =
            useState<Array<AutocompleteOption>>(options);
        const [showSuggestions, setShowSuggestions] = useState(false);
        const wrapperRef = useRef<HTMLDivElement>(null);

        const { t } = useTranslation();

        useEffect(() => {
            function handleClickOutside(event: MouseEvent) {
                if (
                    wrapperRef.current &&
                    !wrapperRef.current.contains(event.target as Node)
                ) {
                    setShowSuggestions(false);
                    setSuggestions(options);
                    setInput('');
                }
            }

            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }, [options]);

        useEffect(() => {
            if (options) {
                setSuggestions(options);
            }
        }, [options]);

        const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            const value = e.target.value;
            setInput(value);
            if (value.length > 0) {
                const filteredSuggestions = options.filter((item) =>
                    item.label.toLowerCase().includes(value.toLowerCase()),
                );
                setSuggestions(filteredSuggestions);
            } else {
                setSuggestions(options);
            }
        };

        const searchInputRef = useRef<HTMLInputElement>(null);

        const handleSuggestionClick = (value?: string) => {
            setShowSuggestions(false);
            setInput('');
            onChange(value);
        };

        const handleSelectedClick = () => {
            setShowSuggestions(true);
            setTimeout(() => {
                searchInputRef.current?.focus();
            }, 0);
        };

        const selectedOption = options.find((option) => option.value === value);

        return (
            <div
                ref={wrapperRef}
                className="flex flex-col items-center justify-center w-full relative"
            >
                <WizardInput
                    type="text"
                    value={selectedOption ? selectedOption.label : ''}
                    onClick={handleSelectedClick}
                    placeholder={placeholder}
                    label={label}
                    ref={ref}
                    readOnly
                    className="pr-10"
                    disabled={disabled}
                >
                    <div className="align-middle absolute top-1/2 -translate-y-1/2 right-4">
                        {!selectedOption && (
                            <MdSearch
                                className={classnames(
                                    'text-primary-4 pointer-events-none',
                                )}
                            />
                        )}
                        {selectedOption && (
                            <button
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleSuggestionClick();
                                }}
                                className="text-gray-400 hover:text-gray-600"
                            >
                                <MdClose
                                    className={classnames('cursor-pointer')}
                                />
                            </button>
                        )}
                    </div>
                    {showSuggestions && (
                        <div className="absolute flex flex-col top-full bg-neutral-50 text-base border-neutral-400 w-full max-h-64 rounded-md transition-all border-2 max-w-flow-text-base z-10">
                            <span className="p-2 flex-1">
                                <WizardInput
                                    type="text"
                                    value={input}
                                    onChange={handleInputChange}
                                    placeholder={t('search')}
                                    ref={searchInputRef}
                                />
                            </span>
                            {suggestions.length > 0 ? (
                                <ul className="flex-1 overflow-y-auto bg-neutral-50 text-base border-neutral-400 w-full rounded-md transition-all max-w-flow-text-base divide-y">
                                    {suggestions.map((suggestion) => (
                                        <li
                                            key={suggestion.value}
                                            onClick={() =>
                                                handleSuggestionClick(
                                                    suggestion.value,
                                                )
                                            }
                                            className={classnames(
                                                'px-4 py-3.5 cursor-pointer hover:bg-primary-4/15 capitalize',
                                                suggestion.value ===
                                                    selectedOption?.value &&
                                                    'bg-primary-4',
                                            )}
                                        >
                                            {suggestion.label}
                                        </li>
                                    ))}
                                </ul>
                            ) : (
                                <p className="px-4 py-3.5 text-neutral-500">
                                    {t('noResultHeadline')}
                                </p>
                            )}
                        </div>
                    )}
                </WizardInput>
            </div>
        );
    },
);

AutocompleteV2.displayName = 'AutocompleteV2';
