import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DefaultPageLayout } from '../../../components/Layout/DefaultPageLayout';
import { useFiveLatestTargetsQuery } from '../../../hooks/queries/useTargetsQuery';
import { useCasesQuery } from '_queries';
import { useUserState } from '../../../context/User';
import { ResultList } from './ResultList';
import { GlobalSearch } from './GlobalSearch';
import { SpotlightTarget } from '@atlaskit/onboarding';

export const LandingPage: FC = () => {
    const { t } = useTranslation();
    const [{ user }] = useUserState();

    const pageSize = 6;

    const {
        data: casesResponse,
        isLoading: isCaseListLoading,
        isFetched: isCaseListFetched,
    } = useCasesQuery({
        pageSize,
        creatorId: user?.username,
    });
    const {
        data: targetsResponse,
        isLoading: isTargetListLoading,
        isFetched: isTargetListFetched,
    } = useFiveLatestTargetsQuery({
        pageSize,
        creatorId: user?.username,
    });

    return (
        <DefaultPageLayout
            title={t('landingPage.title', {
                name: user?.attributes?.firstname,
            })}
            className="h-content-height text-center"
        >
            <SpotlightTarget name="onboardingLandingPageSearch">
                <GlobalSearch />
            </SpotlightTarget>
            <div className="flex justify-between font-jost mt-9 space-x-9 mb-8">
                <ResultList
                    title={t('landingPage.recentCases')}
                    items={casesResponse?.data ?? []}
                    isLoading={isCaseListLoading || !isCaseListFetched}
                />
                <ResultList
                    title={t('landingPage.recentTargets')}
                    items={targetsResponse?.data ?? []}
                    isLoading={isTargetListLoading || !isTargetListFetched}
                />
            </div>
        </DefaultPageLayout>
    );
};
