import { SuspicionArticle } from '_types';
import React from 'react';
import { Press } from './Press';
import { SuspicionItemEntityType } from '_enums';
import { SearchEngine } from './SearchEngine';
import { Company } from './Company';
import { SocialMediaPost } from './SocialMediaPost';

interface ArticlePreviewProps {
    isActive: boolean;
    onClick: () => void;
    article: SuspicionArticle;
}

export const ArticlePreview = (
    props: ArticlePreviewProps,
): React.ReactElement => {
    const { article, isActive, onClick } = props;

    const suspicionCount = article.entity.suspicionItems?.length ?? 0;

    return (
        <>
            {article.entity.type === SuspicionItemEntityType.Press && (
                <Press
                    article={article.entity.value}
                    indicators={article.indicators}
                    suspicionsCount={suspicionCount}
                    isActive={isActive}
                    onClick={onClick}
                />
            )}
            {article.entity.type === SuspicionItemEntityType.GoogleResult && (
                <SearchEngine
                    article={article.entity.value}
                    suspicionsCount={suspicionCount}
                    isActive={isActive}
                    onClick={onClick}
                />
            )}
            {article.entity.type === SuspicionItemEntityType.TargetCompany && (
                <Company
                    article={article.entity.value}
                    suspicionsCount={suspicionCount}
                    isActive={isActive}
                    onClick={onClick}
                />
            )}
            {article.entity.type ===
                SuspicionItemEntityType.SocialMediaPost && (
                <SocialMediaPost
                    article={article.entity.value}
                    suspicionsCount={suspicionCount}
                    isActive={isActive}
                    onClick={onClick}
                />
            )}
        </>
    );
};
