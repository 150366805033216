import { RedFlagItem } from '_types';
import { Badge, Paragraph } from '_atoms';
import React, { FC, useMemo } from 'react';
import { Keyword } from '../Overview/types';

export type RedFlagKeyword = {
    name: string;
    label: string;
    count: number;
};

export const getRedFlagKeywords = (
    redFlags: RedFlagItem[],
): RedFlagKeyword[] => {
    const uniqueKeywords: Record<string, number> = {};
    redFlags.forEach(({ type }) => {
        uniqueKeywords[type] = (uniqueKeywords[type] ?? 0) + 1;
    });

    return Object.entries(uniqueKeywords).map(([type, count]) => ({
        name: type,
        label: type,
        count,
    }));
};

type KeywordsProps = {
    keywords?: Keyword[];
    onClick: (reportLabel: string, suspicionItemId?: string) => void;
    reportLabel: string;
    t: (key: string, params?: Record<string, unknown>) => string;
    className?: string;
};

export const Keywords: FC<KeywordsProps> = ({
    keywords,
    onClick,
    reportLabel,
    t,
    className,
}) => {
    const sortedKeywords = useMemo(
        () => (keywords ?? []).sort((a, b) => b.count - a.count),
        [keywords],
    );
    if (keywords?.length) {
        return (
            <div className={className}>
                {sortedKeywords?.map(
                    ({ label, name, count, suspicionItemId, involvements }) => (
                        <Badge
                            key={label ?? name}
                            background={'light'}
                            size="xSmall"
                            className="mr-1 mt-1 cursor-pointer shadow-md group"
                            paddingClassName="pl-2.5 pr-2 py-1"
                            onClick={(e) => {
                                e.stopPropagation();
                                onClick(reportLabel, suspicionItemId);
                            }}
                        >
                            {t(`suspicionKeywords.${label}`, {
                                defaultValue: name,
                            })}
                            {involvements && involvements.length > 0 && (
                                <Paragraph size={'small'} color={'dark'}>
                                    {`\b ( ${involvements
                                        .map((involvement) =>
                                            t(
                                                `suspicionItemInvolvement.${involvement}`,
                                            ),
                                        )
                                        .join(' / ')} )`}
                                </Paragraph>
                            )}
                            <span className="flex justify-center items-center rounded-full ml-1 bg-neutral-500/50 text-font-light min-w-5 h-5 px-1 group-hover:bg-primary-4 transition-colors">
                                {count}
                            </span>
                        </Badge>
                    ),
                )}
            </div>
        );
    }
    return null;
};
