import { FC, Fragment, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { HiCheck, HiSelector } from 'react-icons/hi';
import classnames from 'classnames';
import { nonProdDataTestId } from '_utils';

export type ColorLevel =
    | 'default'
    | 'red'
    | 'darkRed'
    | 'green'
    | 'yellow'
    | 'blue'
    | 'white';
export type WeightLevel = 'normal' | 'medium' | 'semibold' | 'bold';

export const backgroundColor: Record<ColorLevel, string> = {
    default: 'bg-neutral-50',
    green: 'bg-green-200',
    red: 'bg-error-2',
    darkRed: 'bg-error-1',
    yellow: 'bg-warning-1',
    blue: 'bg-primary-4',
    white: 'bg-neutral-50',
};

export const textColor: Record<ColorLevel, string> = {
    default: 'text-font-dark',
    red: 'text-error-1',
    darkRed: 'text-font-light',
    green: 'text-green-600',
    yellow: 'text-yellow-900',
    blue: 'text-font-light',
    white: 'text-primary-4',
};

export const selBackgroundColor: Record<ColorLevel, string> = {
    default: 'bg-primary-4/15',
    green: 'bg-green-600',
    red: 'bg-error-1',
    darkRed: 'bg-neutral-100',
    yellow: 'bg-yellow-900',
    blue: 'bg-neutral-100',
    white: 'bg-primary-4',
};

export const selTextColor: Record<ColorLevel, string> = {
    default: 'text-font-dark',
    red: 'text-error-2',
    darkRed: 'text-error-1',
    green: 'text-green-200',
    yellow: 'text-warning-1',
    blue: 'text-primary-4',
    white: 'text-font-light',
};

export const checkedColor: Record<ColorLevel, string> = {
    default: 'text-primary-4',
    green: 'text-green-600',
    red: 'text-error-1',
    darkRed: 'text-font-light',
    yellow: 'text-yellow-900',
    blue: 'text-font-light',
    white: 'text-primary-4',
};

export const textWeight: Record<WeightLevel, string> = {
    normal: 'font-normal',
    medium: 'font-medium',
    semibold: 'font-semibold',
    bold: 'font-bold',
};

export type SelectOption = {
    id: number | string;
    label: string;
    value?: string;
};
type SelectProps = {
    label?: string;
    initialSelection?: SelectOption;
    options: SelectOption[];
    onChange?: (value: any) => void;
    className?: string;
    addEmptyOption?: boolean;
    disabled?: boolean;
    color?: ColorLevel;
    weight?: WeightLevel;
    dataTestId?: string;
    preventEventPropagation?: boolean;
    doNotTruncate?: boolean;
};

const emptyOption = {
    id: 0,
    label: '-',
    value: '',
};

export const Select: FC<SelectProps> = ({
    label,
    initialSelection,
    options,
    onChange,
    className,
    addEmptyOption = false,
    disabled = false,
    color = 'default',
    weight = 'normal',
    dataTestId,
    preventEventPropagation,
    doNotTruncate = false,
}: SelectProps) => {
    // if initialSelection is provided and is not empty, use it
    // otherwise, if addEmptyOption is true, use emptyOption
    // otherwise, use null
    const initialSelectionValue =
        initialSelection ?? (addEmptyOption ? emptyOption : null);
    const [selected, setSelected] = useState<SelectOption | null>(
        initialSelectionValue,
    );

    const selectionOptions: SelectOption[] = addEmptyOption
        ? [emptyOption, ...options]
        : options;

    const ListboxSelect = (
        <Listbox
            data-block-card-expand="true"
            value={selected?.value || emptyOption.value}
            onChange={(selection) => {
                const selectedOption = selectionOptions.find(
                    (option) => option.value === selection,
                );

                setSelected(selectedOption || null);
                onChange && onChange(selectedOption?.value);
            }}
            disabled={disabled}
        >
            {({ open }) => (
                <div className={className}>
                    {label && (
                        <Listbox.Label className="block text-sm font-bold font-jost text-neutral-500 mb-1">
                            {label}
                        </Listbox.Label>
                    )}
                    <div className="relative">
                        <Listbox.Button
                            className={classnames(
                                'relative border border-neutral-400 focus:border-primary-4 hover:border-primary-4 block w-full rounded-md transition-all px-4 py-3.5 bg-neutral-50 pr-9 text-left text-base',
                                disabled
                                    ? 'opacity-50'
                                    : [
                                          backgroundColor[color],
                                          textColor[color],
                                      ],
                                textWeight[weight],
                            )}
                            data-testid={nonProdDataTestId(dataTestId)}
                        >
                            <span className="block truncate">
                                {selected && selected.label}
                            </span>
                            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                <HiSelector
                                    className={classnames('h-5 w-5', [
                                        textColor[color],
                                    ])}
                                    aria-hidden="true"
                                />
                            </span>
                        </Listbox.Button>

                        <Transition
                            show={open}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Listbox.Options
                                static
                                className={classnames(
                                    'absolute z-10 mt-1 w-full shadow-card max-h-60 rounded-md py-1 text-base overflow-auto',
                                    [backgroundColor[color], textColor[color]],
                                )}
                            >
                                {selectionOptions.map((option) => (
                                    <Listbox.Option
                                        key={option.id}
                                        className={({ active }) =>
                                            classnames(
                                                active
                                                    ? [
                                                          selTextColor[color],
                                                          selBackgroundColor[
                                                              color
                                                          ],
                                                      ]
                                                    : [
                                                          textColor[color],
                                                          backgroundColor[
                                                              color
                                                          ],
                                                      ],
                                                'cursor-default select-none relative py-2 pl-3 pr-9',
                                            )
                                        }
                                        value={option.value}
                                        data-testid={nonProdDataTestId(
                                            'select level',
                                        )}
                                    >
                                        {({ selected, active }) => (
                                            <>
                                                <span
                                                    className={classnames(
                                                        selected
                                                            ? 'font-semibold'
                                                            : 'font-normal',
                                                        'block',
                                                        !doNotTruncate &&
                                                            'truncate',
                                                    )}
                                                >
                                                    {option.label}
                                                </span>

                                                {selected ? (
                                                    <span
                                                        className={classnames(
                                                            active
                                                                ? selTextColor[
                                                                      color
                                                                  ]
                                                                : checkedColor[
                                                                      color
                                                                  ],
                                                            'absolute inset-y-0 right-0 flex items-center pr-4 transition-all',
                                                        )}
                                                    >
                                                        <HiCheck
                                                            className="h-5 w-5"
                                                            aria-hidden="true"
                                                        />
                                                    </span>
                                                ) : null}
                                            </>
                                        )}
                                    </Listbox.Option>
                                ))}
                            </Listbox.Options>
                        </Transition>
                    </div>
                </div>
            )}
        </Listbox>
    );

    return preventEventPropagation ? (
        <span onClick={(e) => e.stopPropagation()}>{ListboxSelect}</span>
    ) : (
        <>{ListboxSelect}</>
    );
};
