import React, { useMemo } from 'react';
import { SearchEngineArticle } from '../../../../../services/searchService';
import { useTranslation } from 'react-i18next';
import { Meta } from './components/Meta';
import { Divider } from './components/Divider';
import { FullText } from './components/FullText';
import { formatDate } from '_utils';
import {
    HighlightChunks,
    IndicatorTooltip,
} from '../../../../../components/_atoms/Highlight/types';

interface SearchEngineProps {
    article: SearchEngineArticle;
    highlightChunks: HighlightChunks;
    tooltips?: IndicatorTooltip;
}

export const SearchEngine = (props: SearchEngineProps): React.ReactElement => {
    const { article, highlightChunks, tooltips } = props;
    const { t } = useTranslation();

    const meta = useMemo(
        () => [
            {
                label: t('riskAnalysis.articleDetails.source'),
                list: article.sources,
            },
            {
                label: t('riskAnalysis.articleDetails.retrievalDate'),
                list: article.createdDate
                    ? [formatDate(article.createdDate)]
                    : undefined,
            },
        ],
        [article, t],
    );

    return (
        <>
            <Meta meta={meta} />
            <Divider className="my-5" />
            <FullText
                title={article.title}
                text={article.plaintext}
                highlight={highlightChunks}
                tooltips={tooltips}
            />
        </>
    );
};
