import { FC, ReactElement, useEffect, useState } from 'react';
import { Card, ColoredBar } from '_atoms';
import classnames from 'classnames';
import classNames from 'classnames';
import { nonProdDataTestId } from '_utils';
import { FiChevronDown } from 'react-icons/fi';

export type CollapsibleCardProps = {
    initiallyExpanded: boolean;
    toggleEnabled?: boolean;
    header: ReactElement;
    children?: ReactElement | ReactElement[] | null;
    headerColor?: string;
    borderColor?: string;
    headerBorder?: string;
    className?: string;
    bodyClasses?: string;
    headerWrapperClasses?: string;
    id?: string;
    additionalHtmlAttributes?: Record<string, string | undefined>;
    onToggleChange?: (isExpanded: boolean) => void;
};

export const CollapsibleCard: FC<CollapsibleCardProps> = ({
    id,
    header,
    children,
    initiallyExpanded,
    toggleEnabled = true,
    headerColor = 'bg-neutral-100',
    borderColor = 'border-primary-4',
    headerBorder = '',
    className = '',
    bodyClasses = '',
    headerWrapperClasses = '',
    additionalHtmlAttributes,
    onToggleChange,
}) => {
    const [isExpanded, setIsExpanded] = useState<boolean>(
        toggleEnabled && initiallyExpanded,
    );

    useEffect(() => {
        setIsExpanded(toggleEnabled && initiallyExpanded);
    }, [initiallyExpanded, toggleEnabled]);

    const changeExpandState = () => {
        if (!toggleEnabled) {
            return;
        }
        setIsExpanded((prevState) => {
            const newState = !prevState;
            if (onToggleChange !== undefined) {
                onToggleChange(newState);
            }
            return newState;
        });
    };

    return (
        <Card
            id={id}
            statusColor={borderColor}
            className={className}
            additionalHtmlAttributes={additionalHtmlAttributes}
        >
            <ColoredBar
                border={headerBorder}
                className={classNames(
                    headerColor,
                    children && 'cursor-pointer',
                    children && isExpanded && 'rounded-t-md',
                )}
                onClick={children ? changeExpandState : undefined}
            >
                <div
                    className={classNames(
                        'flex w-full',
                        toggleEnabled && 'cursor-pointer',
                        headerWrapperClasses,
                    )}
                >
                    <div className={classNames('w-full')}>{header}</div>
                    <span
                        className={classNames(
                            'flex items-center pl-4 pr-1 cursor-pointer',
                        )}
                        data-testid={nonProdDataTestId('expand card')}
                    >
                        {children && (
                            <FiChevronDown
                                size={28}
                                className={classnames(
                                    'transition-transform',
                                    isExpanded ? 'rotate-180' : 'rotate-0',
                                )}
                            />
                        )}
                    </span>
                </div>
            </ColoredBar>
            {isExpanded && (
                <div
                    className={classnames('px-7.5 py-6', bodyClasses)}
                    data-testid={nonProdDataTestId('expanded card contents')}
                >
                    {children}
                </div>
            )}
        </Card>
    );
};
