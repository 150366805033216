import { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ResultsProps } from '../Results';
import {
    Alert,
    InfiniteScroller,
    Pagination,
    SearchInput,
    WorkflowError,
} from '_molecules';
import type { AllDataArticle } from '../../../services/searchService';
import { useElasticSearch } from '../../../hooks/useElasticSearch';
import { SearchFilterValue } from '_types';
import { Error, LoadingSpinner } from '_atoms';
import {
    CompanyCard,
    PictureCard,
    PressCard,
    ResultCounters,
    SearchResultCard,
    SocialMediaCard,
    WordStratus,
} from '_organisms';

import allDataFilters from './filters.json';
import { nonProdDataTestId } from '_utils';
import { DefaultPageLayout } from '../../../components/Layout/DefaultPageLayout';

const mockKeywords = [
    { value: 'Millionen Dollar', count: 4 },
    { value: 'Geldauflage', count: 23 },
    { value: 'Dating-Riese', count: 5 },
    { value: 'Dresden', count: 5 },
    { value: 'ghitubfui', count: 5 },
    { value: 'ghitubfui', count: 5 },
    { value: 'ghitubfui', count: 5 },
    { value: 'tubfui', count: 5 },
    { value: 'itubfui', count: 5 },
    { value: 'hitubfui', count: 5 },
    { value: 'ghitubfui', count: 5 },
    { value: 'bfui', count: 5 },
];

const initialValues = {
    items: [],
};

const pagingEnabled = false;

type AllDataFilters = {
    publishedAt: SearchFilterValue;
    language: SearchFilterValue;
};

export const AllDataSearch: FC<ResultsProps> = ({
    accessToken,
    targetData,
    caseData,
    entityIndicators,
}: ResultsProps) => {
    const { t } = useTranslation();
    const { caseId, targetId } =
        useParams<{
            caseId: string;
            targetId: string;
        }>();

    const {
        searchInputProps: { renderedFilters, ...searchInputProps },
        data: { items, paging, count } = initialValues,
        isLoading,
        isError,
        handlePageChange,
    } = useElasticSearch<AllDataFilters, AllDataArticle[]>({
        caseId,
        targetId,
        searchFilters: allDataFilters,
        pagingEnabled: pagingEnabled,
    });

    const buildIndicators = (itemId: string) =>
        entityIndicators
            .filter((s) => s.entityId === itemId)
            .flatMap(({ indicators }) => indicators);

    return (
        <DefaultPageLayout title={t('rawData.search')}>
            <SearchInput {...searchInputProps}>{renderedFilters}</SearchInput>
            <ResultCounters count={count} />
            <WordStratus
                keywords={mockKeywords}
                onKeywordClick={(i) => {
                    searchInputProps.onSearchInput(
                        `${searchInputProps.value} ${mockKeywords[i].value}`,
                    );
                }}
                className="hidden"
            />

            <WorkflowError errors={targetData?.workflowErrors} path="profile" />

            {targetData?.status === 'HasInitialProfile' && (
                <Alert
                    alertType="warning"
                    headline={t('profileDataNotFinal')}
                    className="mb-2"
                />
            )}

            <div
                className="relative mt-6 z-10 flex flex-col gap-4"
                data-testid={nonProdDataTestId('all-data-search')}
            >
                {isLoading ? (
                    <div className="absolute top-0 flex justify-center w-full z-0">
                        <LoadingSpinner message={t('profileLoading')} />
                    </div>
                ) : isError ? (
                    <Error
                        headline={t('profileErrorHeadline')}
                        message={t('profileErrorRetryMessage')}
                    />
                ) : null}
                {items.length ? (
                    <Fragment>
                        {items
                            ? items.map((item: AllDataArticle) => {
                                  switch (item.type) {
                                      case 'press':
                                          return (
                                              <PressCard
                                                  key={item.id}
                                                  data={item}
                                                  query={searchInputProps.value}
                                                  targetData={targetData}
                                                  caseData={caseData}
                                                  indicators={buildIndicators(
                                                      item.id,
                                                  )}
                                              />
                                          );
                                      case 'SocialAccountConnection':
                                          return (
                                              <SocialMediaCard
                                                  key={item.id}
                                                  data={item}
                                                  accessToken={accessToken}
                                                  targetData={targetData}
                                                  caseData={caseData}
                                                  indicators={buildIndicators(
                                                      item.id,
                                                  )}
                                              />
                                          );
                                      case 'SocialAccountPost':
                                          return (
                                              <SocialMediaCard
                                                  key={item.id}
                                                  data={item}
                                                  query={searchInputProps.value}
                                                  accessToken={accessToken}
                                                  targetData={targetData}
                                                  caseData={caseData}
                                                  indicators={buildIndicators(
                                                      item.id,
                                                  )}
                                              />
                                          );
                                      case 'Image':
                                          return (
                                              <PictureCard
                                                  key={item.id}
                                                  data={item}
                                                  query={searchInputProps.value}
                                                  targetData={targetData}
                                                  caseData={caseData}
                                                  indicators={buildIndicators(
                                                      item.id,
                                                  )}
                                              />
                                          );
                                      case 'WebPage':
                                          return (
                                              <SearchResultCard
                                                  key={item.id}
                                                  data={item}
                                                  query={searchInputProps.value}
                                                  targetData={targetData}
                                                  caseData={caseData}
                                                  indicators={buildIndicators(
                                                      item.id,
                                                  )}
                                              />
                                          );
                                      case 'TargetCompany':
                                          return (
                                              <CompanyCard
                                                  key={item.id}
                                                  data={item}
                                                  query={searchInputProps.value}
                                                  targetData={targetData}
                                                  caseData={caseData}
                                              />
                                          );
                                  }
                              })
                            : null}

                        {paging?.pagesCount > 1 &&
                            (pagingEnabled ? (
                                <div className="px-5">
                                    <Pagination
                                        paging={paging}
                                        setPageNumber={(page) =>
                                            handlePageChange(page)
                                        }
                                    />
                                </div>
                            ) : (
                                <InfiniteScroller
                                    paging={paging}
                                    isLoading={isLoading}
                                    setPageNumber={(page) =>
                                        handlePageChange(page)
                                    }
                                />
                            ))}
                    </Fragment>
                ) : isLoading ? null : (
                    <Alert
                        alertType="info"
                        message={t('noData')}
                        className="mb-5"
                    />
                )}
            </div>
        </DefaultPageLayout>
    );
};
