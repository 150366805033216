import React from 'react';
import { ArticlePreviewCard } from './components/ArticlePreviewCard';
import { SocialAccountPostArticle } from '../../../../../services/searchService';
import { formatDateTime } from '_utils';

interface SocialMediaPostProps {
    onClick: () => void;
    isActive: boolean;
    article: SocialAccountPostArticle;
    suspicionsCount: number;
}

export const SocialMediaPost = (
    props: SocialMediaPostProps,
): React.ReactElement => {
    const { onClick, isActive, article, suspicionsCount } = props;

    return (
        <ArticlePreviewCard
            onClick={onClick}
            isActive={isActive}
            title={article.postProfileName}
            subtitle={formatDateTime(article.postDatePublished) ?? undefined}
            type="social"
            suspicionsCount={suspicionsCount}
        >
            <div className="text-sm line-clamp-3">{article.postBody}</div>
        </ArticlePreviewCard>
    );
};
