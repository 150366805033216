import { Button, Card, Headline, Input, Paragraph } from '_atoms';
import { useCustomerQuery } from '_queries';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { UserActionTypes, useUserState } from '../../context/User';
import { useUserQuota } from '../../hooks/useUserQuota';
import { routes } from '../../routes';
import {
    setPreferredMFA,
    signout,
    updateUserAttributes,
    User,
    USER_GROUP_ADMINISTRATORS,
    USER_GROUP_SUPER_USERS,
} from '../../services/authenticationService';
import { DefaultPageLayout } from '../../components/Layout/DefaultPageLayout';

export const EditProfile: FC = () => {
    const { t } = useTranslation();
    const [{ user, initialRoute, userRoles }, dispatch] = useUserState();
    const [{ customerId }] = useUserState();
    const { data: customer } = useCustomerQuery(customerId, {
        refetchOnMount: false,
        refetchOnWindowFocus: false,
    });

    const [loading, setLoading] = useState(false);

    const [userAttributes, setUserAttributes] = useState<User['attributes']>({
        email: '',
    });

    useEffect(() => {
        if (user) {
            setUserAttributes(user.attributes);
        }
    }, [user]);

    const { quota } = useUserQuota();

    if (!user) {
        return null;
    }

    const saveUser = async () => {
        try {
            setLoading(true);
            await updateUserAttributes(userAttributes);
            dispatch({
                type: UserActionTypes.SET,
                user: Object.assign({}, user, { attributes: userAttributes }),
            });
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const isAdmin =
        userRoles.includes(USER_GROUP_ADMINISTRATORS) ||
        userRoles.includes(USER_GROUP_SUPER_USERS);
    const isOwner = isAdmin && user.attributes.isCustomerOwner === 'True';

    const userRole = isOwner
        ? t('CustomerOwner')
        : isAdmin
        ? t('CustomerAdmin')
        : t('CustomerUser');

    return (
        <DefaultPageLayout title={t('editProfile')}>
            <Card className="p-9 rounded-2xl space-y-5 *:max-w-150 *:mx-auto">
                <Paragraph>
                    <>
                        <strong>{`${t('email')}: `}</strong>
                        {userAttributes.email}
                    </>
                </Paragraph>
                <Paragraph>
                    {customer && (
                        <>
                            <strong>{`${t('organization')}: `}</strong>
                            {customer.name}
                        </>
                    )}
                </Paragraph>
                <Paragraph>
                    <>
                        <strong>{`${t('privilegeLevel')}: `}</strong>
                        {userRole}
                    </>
                </Paragraph>
                <Paragraph>
                    {quota && (
                        <>
                            <strong>{`${t('quota')}: `}</strong>
                            {`${quota.used}/${
                                quota.total === Infinity ? '∞' : quota.total
                            }`}
                        </>
                    )}
                </Paragraph>
                <br />
                <div className="space-y-6">
                    <Headline Level="h4">
                        {t('authenticationSection.headline')}
                    </Headline>
                    <div className="flex gap-4">
                        <Link to={routes.changePassword.path}>
                            <Button size="small">{t('changePassword')}</Button>
                        </Link>
                        {user.preferredMFA === 'NOMFA' ? (
                            <Link to={routes.setupMfa.path}>
                                <Button size="small" type="button">
                                    {t('authenticationSection.enableMfa')}
                                </Button>
                            </Link>
                        ) : (
                            <Button
                                type="button"
                                size="small"
                                onClick={async () => {
                                    await setPreferredMFA('NOMFA');
                                    await signout();
                                    dispatch({
                                        type: UserActionTypes.SET,
                                        user: null,
                                    });
                                }}
                            >
                                {t('authenticationSection.disableMfa')}
                            </Button>
                        )}
                    </div>
                </div>
                <form className="space-y-5">
                    <Headline Level={'h4'}>{t('editUserData')}</Headline>
                    <Input
                        label={t('firstname')}
                        onChange={(value) => {
                            setUserAttributes((oldUser) => ({
                                ...oldUser,
                                firstname: value.target.value,
                            }));
                        }}
                        value={userAttributes.firstname ?? ''}
                        placeholder={t('firstname')}
                    />

                    <Input
                        label={t('lastname')}
                        onChange={(value) => {
                            setUserAttributes((oldUser) => ({
                                ...oldUser,
                                lastname: value.target.value,
                            }));
                        }}
                        value={userAttributes.lastname ?? ''}
                        placeholder={t('lastname')}
                    />
                </form>
                <div className="flex items-center justify-between gap-6 pt-4">
                    <Link to={initialRoute}>
                        <Button level="primaryGhost">
                            {t('backToOverview')}
                        </Button>
                    </Link>
                    <Button
                        onClick={saveUser}
                        disabled={
                            loading ||
                            !userAttributes.firstname ||
                            !userAttributes.lastname ||
                            (userAttributes.firstname ===
                                user.attributes.firstname &&
                                userAttributes.lastname ===
                                    user.attributes.lastname)
                        }
                    >
                        {t('save')}
                    </Button>
                </div>
            </Card>
        </DefaultPageLayout>
    );
};
