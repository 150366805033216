import { CaseKeyword, Target } from 'src/services/dataService';
import { removeLegalSuffix } from '_utils';
import { HighlightChunks, IndicatorsWithTooltip } from './types';

interface HighlightDeps {
    searchQuery?: string;
    target?: Target;
    indicators?: IndicatorsWithTooltip[];
    caseKeywords?: CaseKeyword[];
}

export const buildNameRegExps = (inputData: Target): ReadonlyArray<RegExp> => {
    const firstname = inputData.firstname.toLowerCase();
    const middlename = inputData.middlename?.toLowerCase() ?? '';
    const lastname = inputData.lastname.toLowerCase();

    const regExps = [
        new RegExp(`\\b${firstname}\\s+${lastname}\\b`, 'im'),
        new RegExp(`\\b${firstname.charAt(0)}\\.\\s+${lastname}\\b`, 'im'),
        new RegExp(`\\b${lastname},\\s+${firstname}\\b`, 'im'),
        new RegExp(`\\b${lastname},\\s+${firstname.charAt(0)}\\.\\b`, 'im'),
    ];

    if (!middlename) {
        return regExps;
    }

    return [
        ...regExps,
        new RegExp(`\\b${firstname}\\s+${middlename}\\s+${lastname}\\b`, 'im'),
        new RegExp(
            `\\b${firstname}\\s+${middlename.charAt(0)}\\s+${lastname}\\b`,
            'im',
        ),
        new RegExp(
            `\\b${firstname}\\s+${middlename.charAt(0)}\\.\\s+${lastname}\\b`,
            'im',
        ),
    ];
};

const getTargetPerson = (target?: Target): ReadonlyArray<RegExp> =>
    target ? buildNameRegExps(target) : [];

export const getTargetCompanies = (target?: Target): ReadonlyArray<RegExp> => {
    const targetCompanies =
        target?.companies
            ?.flatMap((company) => company.data.name)
            .map(({ value }) => value.toLowerCase()) ?? [];

    const uniqueTargetCompanies = [...new Set(targetCompanies)];

    return uniqueTargetCompanies
        .flatMap((companyName) => {
            const trimmedCompanyName = removeLegalSuffix(companyName);
            return trimmedCompanyName.length === companyName.length
                ? [companyName]
                : [companyName, trimmedCompanyName.trim()];
        })
        .map((name) => {
            return new RegExp(`\\b${name}\\b`, 'im');
        });
};

const getCaseKeywords = (caseKeywords?: CaseKeyword[]): string[] =>
    caseKeywords
        ? caseKeywords
              .flatMap((caseKeyword) => caseKeyword.translations)
              .map(({ value }) => value)
        : [];

const getSearchQuery = (searchQuery?: string): string[] =>
    searchQuery ? [searchQuery] : [];

const getIndicatorsQuery = (indicators?: IndicatorsWithTooltip[]): string[] =>
    indicators ? indicators.flatMap(({ highlightText }) => highlightText) : [];

export const buildHighlightChunks = (deps: HighlightDeps): HighlightChunks => ({
    searchQuery: getSearchQuery(deps.searchQuery),
    suspicionKeywords: getIndicatorsQuery(deps.indicators),
    caseKeywords: getCaseKeywords(deps.caseKeywords),
    targetPerson: getTargetPerson(deps.target),
    targetCompanies: getTargetCompanies(deps.target),
});
