import { useQuery, UseQueryResult } from 'react-query';
import { cases } from '../../services/nestApiService';

export const useCaseKeywordsQuery = (caseId: string): UseQueryResult<any> =>
    useQuery(
        ['case', caseId, 'keywords'],
        async () => cases.getKeywords(caseId).then((r) => r.data),
        {
            refetchOnWindowFocus: false,
        },
    );
