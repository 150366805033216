import React from 'react';
import {
    FloatingArrow,
    FloatingPortal,
    useMergeRefs,
} from '@floating-ui/react';
import { useTooltipContext } from './Tooltip';
import classNames from 'classnames';
import { ARROW_HEIGHT, ARROW_WIDTH } from './config';

interface TooltipContentProps extends React.HTMLProps<HTMLDivElement> {
    type?: 'primary' | 'secondary';
}

const mapTypeToTooltipStyles = {
    primary: 'bg-primary-1 text-font-light fill-primary-1',
    secondary: 'bg-neutral-250 text-font-dark fill-neutral-250',
};

export const TooltipContent = React.forwardRef<
    HTMLDivElement,
    TooltipContentProps
>(function TooltipContent(
    { children, type = 'primary', ...restProps },
    propRef,
): React.ReactElement | null {
    const context = useTooltipContext();
    const ref = useMergeRefs([context.refs.setFloating, propRef]);

    if (!context.isMounted) {
        return null;
    }

    const floatingProps = context.getFloatingProps(restProps);

    return (
        <FloatingPortal>
            <div
                {...floatingProps}
                ref={ref}
                style={{
                    ...context.floatingStyles,
                    ...context.transitionStyles,
                    ...restProps.style,
                }}
                className={classNames(
                    'p-4 rounded-md z-40 min-w-40 min-h-12 text-md flex flex-col justify-center shadow-card',
                    mapTypeToTooltipStyles[type],
                    restProps.className,
                )}
            >
                <>
                    {context.withArrow && (
                        <FloatingArrow
                            ref={context.arrowRef}
                            context={context.context}
                            width={ARROW_WIDTH}
                            height={ARROW_HEIGHT}
                            className="fill-inherit"
                        />
                    )}
                    {children}
                </>
            </div>
        </FloatingPortal>
    );
});
