import React, { useMemo } from 'react';
import Highlighter from 'react-highlight-words';
import {
    Paragraph,
    TooltipContentV2,
    TooltipTriggerV2,
    TooltipV2,
} from '_atoms';
import { HighlightChunks, IndicatorTooltip } from './types';
import { computeSearchWords, getColorClass } from './utils';
import { useTranslation } from 'react-i18next';

interface HighlightProps {
    searchWords?: HighlightChunks;
    textToHighlight?: string;
    tooltips?: IndicatorTooltip;
}

export const Highlight = ({
    searchWords,
    textToHighlight,
    tooltips,
}: HighlightProps): React.ReactElement | null => {
    const { t } = useTranslation();
    const computedSearchWords = useMemo(() => {
        if (!textToHighlight) {
            return [];
        }

        return computeSearchWords(searchWords);
    }, [searchWords, textToHighlight]);

    if (!textToHighlight) {
        return null;
    }

    return (
        <Highlighter
            searchWords={computedSearchWords.flatMap(({ words }) => words)}
            textToHighlight={textToHighlight}
            highlightTag={({ children }) => {
                const tooltipList = tooltips?.get(children);
                const displayTooltip = tooltips?.has(children);

                return (
                    <TooltipV2
                        openDelay={400}
                        placement="left-start"
                        withArrow={true}
                        disabled={
                            !displayTooltip ||
                            tooltipList?.filter(
                                (t) => t.suspicionLabel !== null,
                            ).length === 0
                        }
                    >
                        <TooltipContentV2 className="max-w-150 z-40">
                            <Paragraph size="label" weight="bold" color="white">
                                {t('suspicion')}
                            </Paragraph>
                            {tooltipList
                                ?.filter((t) => t.suspicionLabel)
                                .map((tooltip) => (
                                    <div key={tooltip.suspicionLabel}>
                                        <Paragraph
                                            key={tooltip.suspicionLabel}
                                            size="label"
                                            weight="medium"
                                            color="white"
                                        >
                                            {t(
                                                `suspicionKeywords.${tooltip?.suspicionLabel}`,
                                            )}{' '}
                                        </Paragraph>
                                        <Paragraph
                                            key={tooltip.source}
                                            size="label"
                                            weight="normal"
                                            color="white"
                                        >
                                            {' ('}
                                            {t(
                                                `suspicionItemSource.${tooltip?.source}`,
                                            )}
                                            {')'}
                                        </Paragraph>
                                    </div>
                                ))}
                        </TooltipContentV2>
                        <TooltipTriggerV2 asChild={true}>
                            <mark
                                className={getColorClass(
                                    computedSearchWords,
                                    children,
                                )}
                            >
                                {children}
                            </mark>
                        </TooltipTriggerV2>
                    </TooltipV2>
                );
            }}
        />
    );
};
