import { DetailedHTMLProps, forwardRef, InputHTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

interface InputProps
    extends DetailedHTMLProps<
        InputHTMLAttributes<HTMLInputElement>,
        HTMLInputElement
    > {
    label?: string;
    name?: string;
    placeholder?: string;
    recommended?: boolean;
}

export const Input = forwardRef<HTMLInputElement, InputProps>(
    (
        {
            type,
            label,
            name: _name,
            placeholder,
            required,
            recommended,
            className,
            onChange,
            ...rest
        }: InputProps,
        ref,
    ) => {
        const { t } = useTranslation();
        const name = _name || label;

        return (
            <div>
                {(label || required || recommended) && (
                    <div className="flex justify-between mb-1">
                        {label && (
                            <label
                                htmlFor={label}
                                className="block text-sm font-bold font-jost text-neutral-500"
                            >
                                {label}
                            </label>
                        )}
                        {(required || recommended) && (
                            <span className="text-sm text-primary-4">
                                {required ? t('required') : t('recommended')}
                            </span>
                        )}
                    </div>
                )}
                <input
                    type={type ? type : 'text'}
                    name={name}
                    placeholder={placeholder ? placeholder : ''}
                    id={label}
                    required={required}
                    ref={ref}
                    {...rest}
                    onChange={(e) => onChange && onChange(e)}
                    className={classnames(
                        className,
                        'bg-neutral-50 px-4 py-3.5 text-base focus:ring-0 border-neutral-400 focus:border-primary-4 hover:border-primary-4 block w-full rounded-md transition-all border-2',
                        rest.disabled && 'opacity-50',
                    )}
                />
            </div>
        );
    },
);

Input.displayName = 'Input';
