import { useQuery, UseQueryResult } from 'react-query';
import { TargetCandidates } from '../../services/dataService';
import { cases } from '../../services/nestApiService';
import { groupCandidatesResult } from '../../features/targets/TargetCandidates/utils';
import { CaseType } from '../../features/cases/CaseNew';

export const useTargetCandidatesQuery = (
    caseId: string,
    targetId: string,
    caseType: CaseType,
): UseQueryResult<TargetCandidates, string> =>
    useQuery(
        ['targetCandidates', caseId, targetId],
        () =>
            cases
                .getCandidates(caseId, targetId, caseType)
                .then(groupCandidatesResult),
        {
            refetchOnWindowFocus: false,
        },
    );
