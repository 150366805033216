import { DetailedHTMLProps, forwardRef, InputHTMLAttributes } from 'react';
import classnames from 'classnames';

interface WizardFormInputProps
    extends DetailedHTMLProps<
        InputHTMLAttributes<HTMLInputElement>,
        HTMLInputElement
    > {
    label?: string;
    errorMsg?: string;
}

export const WizardInput = forwardRef<HTMLInputElement, WizardFormInputProps>(
    (props, ref) => {
        const { className, label, errorMsg, required, children, ...rest } =
            props;
        return (
            <div className="w-full max-w-flow-text-base mx-auto relative">
                {label && (
                    <label
                        className="block text-sm font-bold font-jost text-neutral-500"
                        htmlFor={rest.id}
                    >
                        {label}
                        {required && <span className="text-primary-4"> *</span>}
                    </label>
                )}
                <input
                    className={classnames(
                        className,
                        'bg-neutral-50 px-4 py-3.5 text-base focus:ring-0 border-neutral-400 focus:border-primary-4 hover:border-primary-4 block w-full rounded-md transition-all border-2',
                        rest.disabled && 'opacity-50',
                    )}
                    ref={ref}
                    {...rest}
                />
                {errorMsg && <div className="text-error-1">{errorMsg}</div>}
                {children}
            </div>
        );
    },
);

WizardInput.displayName = 'WizardInput';
