import React from 'react';
import { ReactComponent as ExclamationIcon } from '_assets/icons/exclamation.svg';
import { ReactComponent as CheckIcon } from '_assets/icons/check.svg';
import { ReactComponent as LoadIcon } from '_assets/icons/loading.svg';
import { Card, Headline, Paragraph } from '_atoms';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

const getAnalysisIconAndLabel = ({
    isCompleted,
    hasSuspicions,
}: {
    isCompleted: boolean;
    hasSuspicions: boolean;
}): { Icon: typeof LoadIcon; iconClassName: string; label: string } => {
    if (!isCompleted) {
        return {
            Icon: LoadIcon,
            label: 'overviewAnalysisIncomplete',
            iconClassName: 'text-blue-500',
        };
    }
    if (hasSuspicions) {
        return {
            Icon: ExclamationIcon,
            label: 'overviewAnalysisResult',
            iconClassName: 'text-yellow-500',
        };
    }
    return {
        Icon: CheckIcon,
        label: 'overviewAnalysisNoResult',
        iconClassName: 'text-green-500',
    };
};

interface OverviewBadgeProps {
    isCompleted: boolean;
    hasSuspicions: boolean;
}

export const OverviewBadge = (
    props: OverviewBadgeProps,
): React.ReactElement => {
    const { isCompleted, hasSuspicions } = props;

    const { t } = useTranslation();

    const { Icon, iconClassName, label } = getAnalysisIconAndLabel({
        isCompleted,
        hasSuspicions,
    });

    return (
        <Card
            statusColor="border-primary-1"
            backgroundColor="bg-primary-1"
            className="py-3 pl-4 pr-4 shadow-md flex justify-between items-center"
        >
            <div className="flex items-center">
                <div className="mr-4">
                    <Icon className={classNames('w-14 h-14', iconClassName)} />
                </div>
                <div>
                    <Headline Level="h3" color="white">
                        {t('overviewAnalysis')}{' '}
                        <span className="align-super text-sm text-primary-4">
                            {t('overviewAnalysisBadge')}
                        </span>
                    </Headline>
                    <Paragraph size="default" color="light">
                        {t(label)}
                    </Paragraph>
                </div>
            </div>
        </Card>
    );
};
