import { FC } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import classnames from 'classnames';

export type StyledLinkProps = LinkProps;

export const StyledLink: FC<StyledLinkProps> = ({ className, ...props }) => (
    <Link
        className={classnames(
            'text-primary-4 hover:text-primary-3 transition disabled:opacity-50',
            className,
        )}
        {...props}
    />
);
