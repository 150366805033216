import axios from 'axios';
import {
    AllSuspicionLabels,
    CrimeLlmSuspicion,
    EntityBySuspicion,
    Report,
    SummaryReport,
    Suspicion,
    SuspicionArticle,
    SuspicionItem,
    SuspicionItemEntity,
    SuspicionsByCategory,
} from '_types';
import {
    Customer,
    UserInputForCreateCustomer,
    UserInputForUpdateCustomer,
    UserInputTarget,
} from '@indicium/common';
import { SuspicionItemStatus } from '_enums';
import config from '../backendConfig.json';
import { getAccessToken } from './authenticationService';
import {
    ReportFilters,
    ReportFiltersWithPaging,
} from '../features/dashboard/RiskAnalysis/ReportCard';
import {
    CustomerUsageResponse,
    Paginated,
    ResourceDeleteResponse,
    Response,
    TargetImage,
} from 'src/types/NestAPI';
import {
    CaseData,
    Paging,
    SocialMediaError,
    Target,
    TargetCandidates,
} from './dataService';
import { UserData } from 'src/types/UserData';
import { generateAllCandidatesArray } from '_utils';
import { EntityIndicator } from '../hooks/queries/useTargetIndicatorsQuery';
import {
    LinkSuspicionItemParams,
    SuspicionItemSource,
} from '../types/SuspicionItem';
import { CasesQueryParams } from '../hooks/queries/useCasesQuery';
import { SuspicionFilters } from '../features/dashboard/RiskAnalysisV2/useFilters';
import { SuspicionArticleFilters } from '../hooks/queries/useSuspicionArticles';
import { CaseType } from '../features/cases/CaseNew';

export const baseURL =
    process.env.REACT_APP_LOCAL === 'true'
        ? 'http://localhost:3080/api'
        : 'https://' + config.backendBaseUrl + '/api';

const client = axios.create({
    baseURL,
});

client.interceptors.request.use(async (config) => {
    try {
        const accessToken = await getAccessToken();
        if (accessToken) {
            config.headers['Authorization'] = 'Bearer ' + accessToken;
        }
    } catch (e) {
        console.error('[nestApiService] error while fetching access token:', e);
    }
    return config;
}, console.error);

client.interceptors.response.use(
    (response) => response,
    (error) => {
        console.error('[nestApiService] error:', error);
        return Promise.reject(error);
    },
);

const paramsSerializer = (params: Record<string, unknown>): string => {
    return Object.entries(params)
        .map(([key, value]) => {
            if (Array.isArray(value)) {
                return value
                    .map((item) => `${key}=${encodeURIComponent(item)}`)
                    .join('&');
            }
            return `${key}=${encodeURIComponent(`${value}`)}`;
        })
        .join('&');
};

const targets = {
    addSuspicion: async (
        targetId: string,
        suspicionId: string,
        entity: SuspicionItemEntity,
    ): Promise<void> =>
        client.post(`targets/${targetId}/suspicions`, {
            entity,
            items: [{ suspicionId, score: 0 }],
        }),
    getSummaryReports: async (
        targetId: string,
        minConfidenceScore?: number,
    ): Promise<SummaryReport[]> =>
        client
            .get(
                `targets/${targetId}/summary/v2${
                    minConfidenceScore === undefined
                        ? ''
                        : `?minConfidenceScore=${minConfidenceScore}`
                }`,
            )
            .then((r) => r.data.data),
    getSuspicionsByCategory: async (
        targetId: string,
        filters: SuspicionFilters,
    ): Promise<SuspicionsByCategory> => {
        return client
            .get(`targets/${targetId}/suspicions-by-category`, {
                params: filters,
                paramsSerializer,
            })
            .then((r) => r.data.data);
    },
    getSuspicionArticles: async (
        { targetId, ...filters }: SuspicionArticleFilters,
        pagination?: Record<'page' | 'pageSize', number>,
    ): Promise<{ data: SuspicionArticle[]; paging: Paging }> =>
        client
            .get(`targets/${targetId}/suspicion-articles`, {
                params: {
                    ...filters,
                    ...pagination,
                },
                paramsSerializer,
            })
            .then((r) => r.data.data),
    getAllSuspicionLabels: async (
        targetId: string,
    ): Promise<AllSuspicionLabels> =>
        client
            .get(`targets/${targetId}/all-suspicions-labels`)
            .then((r) => r.data.data),
    getImages: async (targetId: string): Promise<Response<TargetImage[]>> =>
        client.get(`targets/${targetId}/images`).then((r) => r.data),
    getIndicators: async (
        targetId: string,
        entityId?: string,
    ): Promise<Response<EntityIndicator[]>> =>
        client
            .get(
                `targets/${targetId}/indicators${
                    entityId ? `?entityId=${entityId}` : ''
                }`,
            )
            .then((r) => r.data),
    getInputData: async (
        caseId: string,
        targetId: string,
    ): Promise<Response<UserInputTarget>> =>
        client
            .get(`cases/${caseId}/targets/${targetId}/input`)
            .then((r) => r.data),
    getSelectedCandidates: async (
        caseId: string,
        targetId: string,
        caseType: CaseType,
    ): Promise<TargetCandidates> =>
        client
            .get(`cases/${caseId}/targets/${targetId}/candidates/selected`)
            .then((r) => generateAllCandidatesArray(r.data.data, caseType)),

    getResults: async (caseId: string, targetId: string): Promise<Target> =>
        client
            .get(`targets/${targetId}/cases/${caseId}/results`)
            .then((r) => r.data.data),

    getSocialMediaErrors: async (
        caseId: string,
        targetId: string,
    ): Promise<SocialMediaError[]> =>
        client
            .get(`targets/${targetId}/cases/${caseId}/social-media-errors`)
            .then((r) => r.data.data),
};

const reports = {
    list: async (): Promise<Report> =>
        client
            .get('reports')
            .then((r) =>
                r.data.data.sort((report1: Report, report2: Report) =>
                    report1.name.localeCompare(report2.name),
                ),
            ),
};

const suspicions = {
    list: async (reportLabel?: string, caseId?: string): Promise<Suspicion> => {
        const params: Record<string, string | string[]> = {
            limit: '100',
        };
        if (reportLabel) {
            params['filter.report.label'] = reportLabel;
        }
        if (caseId) {
            params['filter.caseId'] = caseId;
        }

        return client
            .get('suspicions', {
                params,
            })
            .then((r) =>
                r.data.data.sort(
                    (suspicion1: Suspicion, suspicion2: Suspicion) =>
                        suspicion1.name.localeCompare(suspicion2.name),
                ),
            );
    },
    listCrimeLlmSuspicions: async (): Promise<CrimeLlmSuspicion[]> => {
        return client.get(`suspicions/crime-llm`).then((r) => r.data.data);
    },
    listFiltered: async (
        targetId: string,
        reportId: string,
        filters?: ReportFilters,
    ): Promise<Suspicion[]> =>
        client
            .get(`targets/${targetId}/reports/${reportId}/suspicions/v2`, {
                params: {
                    ...(filters?.suspicionItemId
                        ? { suspicionId: filters.suspicionItemId }
                        : {}),
                    ...(filters?.entityType
                        ? { entityType: filters.entityType }
                        : {}),
                    ...(filters?.status ? { status: filters.status } : {}),
                    ...(filters?.involvement
                        ? { involvement: filters.involvement }
                        : {}),
                },
            })
            .then((r) => r.data.data),
    entities: {
        list: async (
            targetId: string,
            reportId: string,
            filters: ReportFiltersWithPaging,
        ): Promise<{ entities: SuspicionItemEntity[]; page: number }> =>
            client
                .get(`targets/${targetId}/reports/${reportId}/entities/v2`, {
                    params: {
                        ...(filters?.suspicionItemId
                            ? { suspicionId: filters.suspicionItemId }
                            : {}),
                        ...(filters?.entityType
                            ? { entityType: filters.entityType }
                            : {}),
                        ...(filters?.status ? { status: filters.status } : {}),
                        ...(filters?.involvement
                            ? { involvement: filters.involvement }
                            : {}),
                        ...{ page: filters.page, pageSize: filters.pageSize },
                        ...(filters.minConfidenceScore !== undefined
                            ? { minConfidenceScore: filters.minConfidenceScore }
                            : {}),
                        ...(filters?.source ? { source: filters.source } : {}),
                    },
                })
                .then((r) => r.data.data),
        patch: async (
            targetId: string,
            reportId: string,
            suspicionIds: string[],
            entity: SuspicionItemEntity,
            source: SuspicionItemSource,
        ): Promise<any> =>
            client
                .patch(`suspicions/entities/${entity.id}`, {
                    targetId,
                    suspicionIds,
                    reportId,
                    entity,
                    source,
                })
                .then((r) => r.data.data),
    },
    items: {
        list: async (
            targetId: string,
            reportId: string,
            filters?: ReportFilters,
        ): Promise<SuspicionItem> =>
            client
                .get(`targets/${targetId}/reports/${reportId}/items`, {
                    params: {
                        ...(filters?.suspicionItemId
                            ? { suspicionId: filters.suspicionItemId }
                            : {}),
                        ...(filters?.entityType
                            ? { entityType: filters.entityType }
                            : {}),
                        ...(filters?.status ? { status: filters.status } : {}),
                        ...(filters?.source ? { source: filters.source } : {}),
                        ...(filters?.involvement
                            ? { involvement: filters.involvement }
                            : {}),
                    },
                })
                .then((r) => r.data.data),
        listBySuspicion: async (
            targetId: string,
            suspicionId: string,
            filters: ReportFiltersWithPaging,
        ): Promise<{ entities: EntityBySuspicion[]; paging: Paging }> =>
            client
                .get(
                    `targets/${targetId}/suspicions/${suspicionId}/itemsPaginated`,
                    {
                        params: {
                            ...(filters?.suspicionItemId
                                ? { suspicionId: filters.suspicionItemId }
                                : {}),
                            ...(filters?.entityType
                                ? { entityType: filters.entityType }
                                : {}),
                            ...(filters?.status
                                ? { status: filters.status }
                                : {}),
                            ...(filters?.involvement
                                ? { involvement: filters.involvement }
                                : {}),
                            ...{
                                page: filters.page,
                                pageSize: filters.pageSize,
                            },
                            ...(filters.minConfidenceScore !== undefined
                                ? {
                                      minConfidenceScore:
                                          filters.minConfidenceScore,
                                  }
                                : {}),
                            ...(filters?.source
                                ? { source: filters.source }
                                : {}),
                        },
                    },
                )
                .then((r) => r.data.data),
        entities: {
            find: async (
                entityId: string,
                targetId: string,
            ): Promise<SuspicionItemEntity & { suspicions: Suspicion[] }> =>
                client
                    .get(`suspicions/items/entities/${entityId}/${targetId}`)
                    .then((r) => r.data.data),
        },
        create: async (
            targetId: string,
            suspicionId: string,
            entity: SuspicionItemEntity,
            status = SuspicionItemStatus.Problem,
            source = SuspicionItemSource.User,
        ): Promise<SuspicionItem> =>
            client.post(`suspicions/items`, {
                entity,
                targetId,
                suspicionId,
                status,
                source,
            }),
        update: async (
            itemId: string,
            status: SuspicionItemStatus,
        ): Promise<void> =>
            client.patch(`suspicions/items/${itemId}`, { status }),
        updateMany: async (
            ids: string[],
            status: SuspicionItemStatus,
        ): Promise<void> => client.patch(`suspicions/items`, { ids, status }),
        link: async (payload: LinkSuspicionItemParams): Promise<void> =>
            client.post(`suspicions/link`, payload),
    },
};

const customers = {
    findAll: async (): Promise<Paginated<Customer>> =>
        client.get('customers').then((r) => r.data),

    findOne: async (customerId: string): Promise<Response<Customer>> =>
        client.get(`customers/${customerId}`).then((r) => r.data),

    create: async (
        payload: UserInputForCreateCustomer,
    ): Promise<Response<Customer>> =>
        client.post('customers', payload).then((r) => r.data),

    update: async (
        customerId: string,
        payload: UserInputForUpdateCustomer,
    ): Promise<Response<Customer>> =>
        client.patch(`customers/${customerId}`, payload).then((r) => r.data),

    delete: async (
        customerId: string,
    ): Promise<Response<ResourceDeleteResponse>> =>
        client.delete(`customers/${customerId}`).then((r) => r.data),

    usage: async (
        customerId: string,
    ): Promise<Response<CustomerUsageResponse>> =>
        client.get(`customers/${customerId}/usage`).then((r) => r.data),
};

const cases = {
    findOne: (caseId?: string): Promise<Response<CaseData>> =>
        client.get(`cases/${caseId}`).then((r) => r.data),

    getKeywords: (caseId: string): Promise<Response<any>> =>
        client.get(`cases/${caseId}/keywords`).then((r) => r.data),

    getAll: (queryParams?: CasesQueryParams): Promise<Paginated<CaseData>> =>
        client
            .get('cases', {
                params: queryParams,
            })
            .then((r) => r.data),
    getTargets: (
        caseId: string,
        queryParams?: { query?: string },
    ): Promise<Paginated<Target>> =>
        client
            .get(`cases/${caseId}/targets`, {
                params: queryParams,
            })
            .then((r) => r.data),
    getLatestTargetList: (queryParams?: {
        page?: string;
        pageSize?: number;
        query?: string;
        creatorId?: string;
    }): Promise<Paginated<Target>> =>
        client
            .get(`targets`, {
                params: queryParams,
            })
            .then((r) => r.data),
    getCandidates: (
        caseId: string,
        targetId: string,
        caseType: CaseType,
    ): Promise<TargetCandidates> =>
        client
            .get(`cases/${caseId}/targets/${targetId}/candidatesV2`)
            .then((r) => generateAllCandidatesArray(r.data.data, caseType)),
    postPreselectedCandidates: (
        caseId: string,
        targetId: string,
        forceFirstStepCandidates?: string[],
    ): Promise<TargetCandidates['preSelectedCandidates']> =>
        client.post(
            `cases/${caseId}/targets/${targetId}/preselection`,
            {
                forceFirstStepCandidates,
            },
            {
                timeout: 30000,
            },
        ),
    getPreselectedCandidates: (
        caseId: string,
        targetId: string,
    ): Promise<TargetCandidates['preSelectedCandidates']> =>
        client
            .get(`cases/${caseId}/targets/${targetId}/preselection`)
            .then((r) => r.data.data),
    search: (queryParams: { query: string }): Promise<TargetCandidates> =>
        client.post(`cases/search`, { queryParams }).then((r) => r.data.data),
};

const app = {
    version: async (): Promise<any> =>
        client.get('version').then((r) => r.data),
};

const users = {
    getAll: async (customerId?: string): Promise<UserData[]> =>
        client
            .get(`users${customerId ? `?customerId=${customerId}` : ''}`)
            .then((r) => r.data.data),
};

export { app, targets, reports, suspicions, customers, cases, users };
