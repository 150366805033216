import {
    FormFieldConfig,
    FormSchema,
    FormSchemaArrayKeys,
    FormType,
    formTypes,
} from './form.interface';
import {
    ContactType,
    DynamicFormValueStatus,
    SocialMediaType,
    Title,
} from '@indicium/common';
import {
    getCountrySelectionOptions,
    getNationalitySelectionOptions,
} from '../countryCodeOptions';
import i18n, { t } from 'i18next';
import { AutocompleteOption } from '../../../../components/_atoms/AutoComplete/AutoComplete';

const nationalitySelectionOptions = getNationalitySelectionOptions(
    i18n.language,
);

const countrySelectionOptions = getCountrySelectionOptions(i18n.language);

export const formTypeOptions: AutocompleteOption[] = formTypes
    .filter((type) => type !== 'name')
    .map((type) => ({
        value: type,
        label: type,
    }));

export const nameFormConfig: FormFieldConfig[] = [
    {
        key: 'title',
        labelTranslationKey: 'title',
        type: 'select',
        options: Object.values(Title).map((title) => ({
            id: title,
            value: title,
            labelTranslationKey: title,
        })),
    },
    {
        key: 'gender',
        labelTranslationKey: 'gender',
        type: 'select',
        options: [
            { value: 'male', labelTranslationKey: 'Male' },
            { value: 'female', labelTranslationKey: 'Female' },
        ],
    },
    {
        key: 'firstName',
        labelTranslationKey: 'firstname',
        type: 'text',
        validators: {
            required: 'schemaValidation.required',
        },
    },
    {
        key: 'lastName',
        labelTranslationKey: 'lastname',
        type: 'text',
        validators: {
            required: 'schemaValidation.required',
        },
    },
    {
        key: 'middleName',
        labelTranslationKey: 'middlename',
        type: 'text',
    },
];

export const birthInformationFormConfig: FormFieldConfig[] = [
    {
        key: 'dateOfBirth',
        labelTranslationKey: 'dateOfBirth',
        type: 'date',
    },
    {
        key: 'placeOfBirth',
        labelTranslationKey: 'placeOfBirth',
        type: 'text',
    },
    {
        key: 'countryOfBirth',
        labelTranslationKey: 'countryOfBirth',
        type: 'select',
        options: countrySelectionOptions.map(({ code, name }) => {
            return {
                id: code,
                value: code,
                labelTranslationKey: name,
            };
        }),
    },
];

export const residenceFormConfig: FormFieldConfig[] = [
    {
        key: 'countryOfResidence',
        labelTranslationKey: 'countryOfResidence',
        type: 'select',
        options: countrySelectionOptions.map(({ code, name }) => {
            return {
                id: code,
                value: code,
                labelTranslationKey: name,
            };
        }),
    },
];

export const nationalityFormConfig: FormFieldConfig[] = [
    {
        key: 'nationality',
        labelTranslationKey: 'nationality',
        type: 'select',
        options: nationalitySelectionOptions.map(({ code, name }) => {
            return {
                id: code,
                value: code,
                labelTranslationKey: name,
            };
        }),
    },
];

const MIN_LENGTH = 5;

export const jobsFormConfig: FormFieldConfig[] = [
    {
        key: 'jobTitle',
        labelTranslationKey: 'jobTitle',
        type: 'text',
    },
    {
        key: 'jobDate',
        labelTranslationKey: 'jobDate',
        type: 'date',
    },
    {
        key: 'selfEmployed',
        labelTranslationKey: 'selfEmployed',
        type: 'checkbox',
    },

    {
        key: 'companyName',
        labelTranslationKey: 'jobCompanyName',
        type: 'text',
        validators: {
            required: 'schemaValidation.required',
        },
    },
    {
        key: 'companyWebsite',
        labelTranslationKey: 'jobCompanyWebsite',
        type: 'url',
    },
    {
        key: 'companyVatNumber',
        labelTranslationKey: 'jobCompanyVatNumber',
        type: 'text',
        validators: {
            minLength: {
                value: MIN_LENGTH,
                message: t('schemaValidation.minimumCharacters', {
                    minLength: MIN_LENGTH,
                }),
            },
        },
    },
    {
        key: 'companyCommercialRegisterNumber',
        labelTranslationKey: 'jobCompanyCommercialRegisterNumber',
        type: 'text',
        validators: {
            minLength: {
                value: MIN_LENGTH,
                message: t('schemaValidation.minimumCharacters', {
                    minLength: MIN_LENGTH,
                }),
            },
        },
    },
    {
        key: 'companyCountry',
        labelTranslationKey: 'jobCompanyLocation',
        type: 'select',
        options: countrySelectionOptions.map(({ code, name }) => {
            return {
                id: code,
                value: code,
                labelTranslationKey: name,
            };
        }),
    },
    {
        key: 'companyCity',
        labelTranslationKey: 'jobCompanyCity',
        type: 'text',
    },
];

export const nicknameFormConfig: FormFieldConfig[] = [
    {
        key: 'nickname',
        labelTranslationKey: 'Nickname',
        type: 'text',
    },
];

export const socialMediaFormConfig: FormFieldConfig[] = Object.values(
    SocialMediaType,
).map((socialMediaType) => ({
    key: socialMediaType,
    labelTranslationKey: socialMediaType,
    type: 'text',
    placeholder: `${socialMediaType}Placeholder`,
}));

export const contactFormConfig: FormFieldConfig[] = Object.values(
    ContactType,
).map((type) => ({
    key: type,
    labelTranslationKey: type,
    type: type === 'email' ? 'email' : 'text',
    placeholder: `${type}Placeholder`,
}));

export const educationFormConfig: FormFieldConfig[] = [
    {
        key: 'title',
        labelTranslationKey: 'educationTitle',
        type: 'text',
    },
    {
        key: 'type',
        labelTranslationKey: 'educationType',
        type: 'text',
    },
    {
        key: 'date',
        labelTranslationKey: 'educationDate',
        type: 'dateRange',
        start: {
            key: 'start',
            labelTranslationKey: 'educationTypeStart',
            type: 'date',
        },
        end: {
            key: 'end',
            labelTranslationKey: 'educationTypeEnd',
            type: 'date',
        },
    },
    {
        key: 'institutionName',
        labelTranslationKey: 'educationInstitutionName',
        type: 'text',
    },
    {
        key: 'institutionLocation',
        labelTranslationKey: 'educationInstitutionLocation',
        type: 'text',
    },
];

// TODO: do we need these
// export const bankAccountFormConfig: FormFieldConfig[] = [
//     {
//         key: 'iban',
//         label: 'IBAN',
//         type: 'text',
//         validators: {
//             required: 'schemaValidation.required',
//         },
//     },
//     {
//         key: 'bic',
//         label: 'BIC',
//         type: 'text',
//         validators: {
//             required: 'schemaValidation.required',
//         },
//     },
// ];

export const imagesFormConfig: FormFieldConfig[] = [
    {
        key: 'image',
        labelTranslationKey: 'Image URL',
        type: 'text',
    },
];

// Dynamic Form Values (for relatedPersons, websites, organizations, topics, locations, nicknames)
function createDynamicFormValueConfig(prefix: string): FormFieldConfig[] {
    return [
        {
            key: `${prefix}Status`,
            labelTranslationKey: 'Status',
            type: 'select',
            options: [
                {
                    value: DynamicFormValueStatus.Confirmed,
                    labelTranslationKey: 'dynamicForm.buttons.include',
                },
                {
                    value: DynamicFormValueStatus.Ignored,
                    labelTranslationKey: 'dynamicForm.buttons.exclude',
                },
            ],
        },
        {
            key: `${prefix}Value`,
            labelTranslationKey: 'Value',
            type: 'text',
        },
    ];
}

export const relatedPersonsFormConfig =
    createDynamicFormValueConfig('relatedPerson');
export const websitesFormConfig = createDynamicFormValueConfig('website');
export const organizationsFormConfig =
    createDynamicFormValueConfig('organization');
export const topicsFormConfig = createDynamicFormValueConfig('topic');
export const locationsFormConfig = createDynamicFormValueConfig('location');
export const nicknamesFormConfig = createDynamicFormValueConfig('nickname');

export const formConfigMap: Record<
    NonNullable<FormType>,
    {
        formFields: FormFieldConfig[];
        parentKey?: NonNullable<keyof FormSchema>;
        arrayPath?: NonNullable<FormSchemaArrayKeys>;
    }
> = {
    name: {
        formFields: nameFormConfig,
    },
    birthInfo: {
        formFields: birthInformationFormConfig,
    },
    residenceInfo: {
        formFields: residenceFormConfig,
    },
    nationalities: {
        formFields: nationalityFormConfig,
        arrayPath: 'nationalities',
    },
    contactInfo: {
        formFields: contactFormConfig,
        parentKey: 'contact',
    },
    educationInfo: {
        formFields: educationFormConfig,
        arrayPath: 'education',
    },

    socialMediaProfiles: {
        formFields: socialMediaFormConfig,
        parentKey: 'socialMediaProfiles',
    },
    jobs: {
        formFields: jobsFormConfig,
        arrayPath: 'jobs',
    },
    // nickname: {
    //     formFields: nicknameFormConfig,
    //     arrayPath: 'nicknames',
    // },
    images: {
        formFields: imagesFormConfig,
        arrayPath: 'images',
    },
    relatedPersons: {
        formFields: relatedPersonsFormConfig,
        arrayPath: 'relatedPersons',
    },
    websites: {
        formFields: websitesFormConfig,
        arrayPath: 'websites',
    },
    organizations: {
        formFields: organizationsFormConfig,
        arrayPath: 'organizations',
    },
    topics: {
        formFields: topicsFormConfig,
        arrayPath: 'topics',
    },
    locations: {
        formFields: locationsFormConfig,
        arrayPath: 'locations',
    },
    nicknames: {
        formFields: nicknamesFormConfig,
        arrayPath: 'nicknames',
    },
};
