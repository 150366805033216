import React, { FC } from 'react';
import { LanguageSwitcher } from '_molecules';
import { useUserState } from '../../context/User';
import { CaseToProfileBreadcrumbs } from './CaseToProfileBreadcrumbs';
import { Paragraph } from '_atoms';
import { Link, useRouteMatch, useLocation } from 'react-router-dom';
import { routes } from '../../routes';
import classnames from 'classnames';
import { SpotlightTarget } from '@atlaskit/onboarding';
import { HiMiniQuestionMarkCircle } from 'react-icons/hi2';

import { useSpotlightContext } from '../../context/Spotlight';
import { ContactSupportButton } from '_organisms/SomethingWentWrongMessage/SomethingWentWrongMessage';

export const TopNavigationBar: FC = () => {
    const [{ user }] = useUserState();
    const { start } = useSpotlightContext();

    const resultsPageMatch = useRouteMatch(routes.dashboardOverview.path);
    const { pathname } = useLocation();

    return (
        <div
            className={classnames(
                'relative h-20 w-full bg-neutral-100 flex shadow-card z-10 px-9 items-center transition-all print:hidden',
                resultsPageMatch ? 'rounded-bl-2xl' : 'rounded-bl-none',
            )}
        >
            <SpotlightTarget name="onboardingTopMenuBreadcrums">
                <CaseToProfileBreadcrumbs className="flex-shrink-0" />
            </SpotlightTarget>
            {/* this is a placeholder for the search bar */}
            <div className="flex-grow" />
            <div className="flex items-center gap-9 flex-shrink-0">
                <a
                    className={classnames(
                        'text-sm underline text-primary-4 cursor-pointer',
                        pathname === '/' ? 'visible' : 'invisible',
                    )}
                >
                    <HiMiniQuestionMarkCircle size={24} onClick={start} />
                </a>
                <ContactSupportButton type="icon" />
                <SpotlightTarget name="onboardingTopMenuLanguage">
                    <LanguageSwitcher />
                </SpotlightTarget>
                <SpotlightTarget name="onboardingTopMenuProfile">
                    <Link
                        to="/profile"
                        className="bg-primary-1 hover:bg-primary-4 transition-colors w-12 h-12 rounded-full flex justify-center items-center min-w-12"
                    >
                        <Paragraph size="medium" weight="bold" color="white">
                            {user?.attributes?.firstname?.slice(0, 1)}
                            {user?.attributes?.lastname?.slice(0, 1)}
                        </Paragraph>
                    </Link>
                </SpotlightTarget>
            </div>
        </div>
    );
};
